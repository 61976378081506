import Checkbox from '@/components/atoms/Checkbox';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Button from '../atoms/Button';
import { Label } from '../atoms/Label';
import SearchBar from '../atoms/SearchBar';
import SelectInput from '../atoms/Select';

export type Filter = {
  key: string;
  name: string;
  values: {
    value: any;
    label: string;
  }[];
  asyncFn?: any;
  type?: string;
};

type OilwellCheckbox = {
  nome: string;
  checked: boolean;
};

interface OilwellFiltersCardProps {
  name: string;
  filters: Filter[];
  fetchOilwells: (data: FieldValues) => Promise<string[]>;
  onOilwellCheckboxChange: (checkedOilwells: string[]) => void;
}

const OilwellFiltersCard = ({
  filters,
  fetchOilwells,
  onOilwellCheckboxChange
}: OilwellFiltersCardProps) => {
  const methods = useForm();
  const { setValue } = methods;

  const [oilwellCheckbox, setOilwells] = useState<OilwellCheckbox[]>([]);

  useEffect(() => {
    const checkedOilwells = oilwellCheckbox
      .filter(oilwell => oilwell.checked)
      .map(oilwell => oilwell.nome);

    onOilwellCheckboxChange(checkedOilwells);
  }, [oilwellCheckbox, onOilwellCheckboxChange]);

  const handleOilwellCheckboxChange =
    (oilwellName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setOilwells(prevOilwells =>
        prevOilwells.map(oilwell =>
          oilwell.nome === oilwellName
            ? { ...oilwell, checked: checked }
            : oilwell
        )
      );
    };

  const handleSetAllOilwells = (checked: boolean) => () => {
    setOilwells(prevOilwells =>
      prevOilwells.map(oilwell => ({
        ...oilwell,
        checked
      }))
    );
  };

  const handleSubmit = methods.handleSubmit(async data => {
    const oilwells = await fetchOilwells(data);
    setOilwells(createOilwellCheckboxes(oilwells));
  });

  return (
    <>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-4 shadow">
        <Label
          id="Opções de Filtragem"
          className="text-primary"
          name="Opções de Filtragem"
          noBorder
        />

        <div className="grid grid-rows-2 grid-cols-6 items-center gap-x-2">
          <div className="col-span-3 flex items-center">
            <SearchBar
              onChange={e => setValue('search', e.target.value)}
              placeholder="Pesquisar..."
            />
          </div>

          <div className="col-span-3 flex items-center"></div>

          {filters.map(filter => (
            <div key={filter.key}>
              <div className="pl-2 text-sm font-medium text-slate-800/50">
                {filter.name}
              </div>
              <SelectInput
                onChange={e =>
                  setValue(
                    filter.key,
                    e.map(item => item.value)
                  )
                }
                options={filter.values}
                asyncFn={filter.asyncFn}
                secondary={true}
              />
            </div>
          ))}

          <div className="h-full flex items-end">
            <Button
              title="Filtrar"
              onClick={handleSubmit}
              className="h-8 w-full"
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div className="text-sm font-medium text-slate-800">
              {oilwellCheckbox.length} poços identificados
            </div>
            <Button
              title="Selecionar Todos"
              onClick={handleSetAllOilwells(true)}
              className="text-sm font-medium h-6"
            />
            <button
              className="text-sm text-primary hover:text-gray-800 transition-colors"
              onClick={handleSetAllOilwells(false)}
            >
              Desmarcar Tudo
            </button>
          </div>
          <div className="flex flex-row gap-2 overflow-y-auto px-2 whitespace-nowrap ">
            {oilwellCheckbox.map(checkbox => (
              <Checkbox
                key={checkbox.nome}
                label={checkbox.nome}
                checked={checkbox.checked}
                onChange={handleOilwellCheckboxChange(checkbox.nome)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const createOilwellCheckboxes = (oilwells: string[]): OilwellCheckbox[] => {
  if (!oilwells) return [];

  return oilwells.map(oilwell => ({
    nome: oilwell,
    checked: false
  }));
};

export default OilwellFiltersCard;
