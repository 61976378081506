import CustomStyleCell from '@/components/atoms/table/CustomStyleCell';
import { DefaultCellWithParser } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { InputProps } from '@/components/atoms/table/InputMaskCell';
import SimpleTable from '@/components/molecules/SimpleTable';
import { getNestedValue } from '@/utils/table';
import { format } from 'date-fns';
import { HTMLInputTypeAttribute, useEffect, useRef, useState } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { FaInfoCircle, FaPen } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import { AlertSection, SectionConfig } from '../types';
import { formatStringToNumber } from '@/utils/utils';
import Modal from '@/components/atoms/Modal';
import { Label } from '@/components/atoms/Label';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const AlertTables = ({
  oilwell,
  section,
  defaultValues,
  methods
}: {
  oilwell: string;
  section: AlertSection;
  defaultValues: SectionConfig;
  methods: UseFormReturn<any, any, undefined>;
}) => {
  // TODO: ajustar cores para o tema

  const [editableCells, setEditableCells] = useState<Record<string, boolean>>(
    {}
  );

  const setCellEditable = (cellKey: string, isEditable: boolean) => {
    setEditableCells(prev => ({
      ...prev,
      [cellKey]: isEditable
    }));
  };

  const isCellEditable = (cellKey: string): boolean => {
    return editableCells[cellKey] || false;
  };

  return (
    <div className="flex flex-col gap-4">
      {defaultValues.percentage_change_alert?.length > 0 && (
        <SimpleTable
          columns={getThreeLevelsColumns(
            `${oilwell}.${section}.percentage_change_alert`,
            isCellEditable,
            setCellEditable,
            methods,
            '%',
            false
          )}
          data={defaultValues.percentage_change_alert}
        />
      )}

      {defaultValues.absolute_difference_alert?.length > 0 && (
        <SimpleTable
          columns={getThreeLevelsColumns(
            `${oilwell}.${section}.absolute_difference_alert`,
            isCellEditable,
            setCellEditable,
            methods,
            '°C',
            true
          )}
          data={defaultValues.absolute_difference_alert}
        />
      )}

      {defaultValues.ratio_alert?.length > 0 && (
        <SimpleTable
          columns={getTwoLevelsColumns(
            `${oilwell}.${section}.ratio_alert`,
            isCellEditable,
            setCellEditable
          )}
          data={defaultValues.ratio_alert}
        />
      )}
    </div>
  );
};

const getThreeLevelsColumns = (
  name: string,
  isCellEditable: (cellKey: string) => boolean,
  setCellEditable: (cellKey: string, isEditable: boolean) => void,
  methods: UseFormReturn<any, any, undefined>,
  unit: string,
  isAbosoluteValue: boolean
) => [
  {
    Header: (
      <div className="relative">
        <Header
          terciary
          text={
            isAbosoluteValue ? 'Variação absoluta' : 'Variação em percentual'
          }
          id="label"
        />
        {isAbosoluteValue && (
          <>
            <FaInfoCircle
              className="absolute left-28 top-2 text-primary w-3.5 h-3.5"
              data-tooltip-id={`variable-tooltip-label-${name}`}
              data-tooltip-target="tooltip-default"
            />
            <ReactTooltip
              id={`variable-tooltip-label-${name}`}
              place="top"
              variant="light"
              style={{ maxWidth: '250px', textAlign: 'center' }}
              content="Alertas em relação a variação absoluta entre o valor atual e o anterior"
            />
          </>
        )}
      </div>
    ),
    accessor: 'label',
    Cell: CustomStyleCell({
      appendClassName:
        'w-full py-1 px-2 bg-[#F5F7FF] text-primary text-xs items-center'
    }),
    minWidth: 120
  },
  {
    Header: (
      <Header terciary text="Verde" id="green" className="text-[#0C8A6C]" />
    ),
    accessor: 'green',
    Cell: ({ row: { index } }: any) => (
      <InputCellWithIcon
        name={`${name}.${index}.green`}
        inputType="number"
        isEditable={isCellEditable(`${name}.${index}.green`)}
        setIsEditable={x => setCellEditable(`${name}.${index}.green`, x)}
        unit={unit}
        prefix={'≤'}
        onChange={e => {
          const value = formatStringToNumber(e.target.value);
          methods.setValue(`${name}.${index}.green`, value);
        }}
      />
    ),
    minWidth: 120
  },
  {
    Header: (
      <Header terciary text="Vermelho" id="red" className=" text-[#A60000]" />
    ),
    accessor: 'red',
    Cell: ({ row: { index } }: any) => (
      <InputCellWithIcon
        name={`${name}.${index}.red`}
        inputType="number"
        isEditable={isCellEditable(`${name}.${index}.red`)}
        setIsEditable={x => setCellEditable(`${name}.${index}.red`, x)}
        unit={unit}
        prefix={'>'}
      />
    ),
    minWidth: 120
  },
  {
    Header: (
      <Header terciary text="Amarelo" id="yellow" className="text-[#AD953C]" />
    ),
    accessor: 'yellow',
    Cell: ({ row: { index } }: any) => {
      const {
        watch,
        formState: { dirtyFields }
      } = methods;

      const watchedValues = watch(name);

      const redValue = watchedValues?.[index]?.red;
      const greenValue = watchedValues?.[index]?.green;
      const yellowValue = `${greenValue}${unit} - ${redValue}${unit}`;

      const dirtyLocal = getNestedValue(dirtyFields, name);

      return CustomStyleCell({
        appendClassName: twMerge(
          'py-1 text-xs pl-2 rounded',
          dirtyLocal?.[index]?.red || dirtyLocal?.[index]?.green
            ? 'bg-persian-blue-100'
            : ''
        )
      })({
        value: yellowValue
      });
    },
    minWidth: 120
  },
  {
    Header: <Header terciary text="Última Atualização" id="lastUpdate" />,
    accessor: 'last_update',
    Cell: DetailsCell,
    minWidth: 120
  }
];

const getTwoLevelsColumns = (
  name: string,
  isCellEditable: (cellKey: string) => boolean,
  setCellEditable: (cellKey: string, isEditable: boolean) => void
) => [
  {
    Header: (
      <div className="relative">
        <Header terciary text="Variação absoluta" id="label" />
        <FaInfoCircle
          className="absolute left-28 top-2 text-primary w-3.5 h-3.5"
          data-tooltip-id={`variable-tooltip-label-${name}`}
          data-tooltip-target="tooltip-default"
        />
        <ReactTooltip
          id={`variable-tooltip-label-${name}`}
          place="top"
          variant="light"
          style={{ maxWidth: '250px', textAlign: 'center' }}
          content="Alertas em relação ao limite estabelecido. Valores acima do limite são verdes e abaixo são vermelhos"
        />
      </div>
    ),
    accessor: 'label',
    Cell: ({ value }: any) => {
      return CustomStyleCell({
        appendClassName:
          'w-full py-1 px-2 bg-[#F5F7FF] text-primary text-xs items-center flex gap-2'
      })({
        value: value
      });
    },
    minWidth: 120
  },
  {
    Header: <Header terciary text="Limite" id="limite" />,
    accessor: 'green',
    Cell: ({ row: { index } }: any) => (
      <InputCellWithIcon
        name={`${name}.${index}.green`}
        inputType="number"
        isEditable={isCellEditable(`${name}.${index}.green`)}
        setIsEditable={x => setCellEditable(`${name}.${index}.green`, x)}
        unit="°C"
        prefix="≤"
      />
    ),
    minWidth: 120
  },
  {
    Header: <Header terciary text="Última Atualização" id="lastUpdate" />,
    accessor: 'last_update',
    Cell: DetailsCell,
    minWidth: 120
  }
];

const InputCellWithIcon = ({
  name,
  className,
  isEditable,
  setIsEditable,
  unit,
  prefix
}: {
  inputType: HTMLInputTypeAttribute;
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
  unit?: string;
  prefix?: string;
} & InputProps) => {
  const {
    register,
    resetField,
    formState: { dirtyFields }
  } = useFormContext();
  const isDirty = getNestedValue(dirtyFields, name);

  const spanRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        setIsEditable(false);
      }
    };

    if (isEditable) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [spanRef]);

  return (
    <span
      ref={spanRef}
      className={twMerge('relative text-body-dark text-xs flex items-center')}
    >
      <input
        {...register(name)}
        type="text"
        autoFocus
        disabled={!isEditable}
        className={twMerge(
          'form-control js-inputmask bg-transparent pl-2 h-6 w-full text-left rounded bg-white active:border-border-gray-dark',
          isDirty && 'bg-persian-blue-100',
          className ?? ''
        )}
      />
      <span className="absolute left-12">{unit}</span>
      <div className="absolute left-0">{prefix}</div>

      {isEditable ? (
        <button
          className="absolute right-2 bg-slate-50 hover:bg-slate-100 p-1 rounded-full transition-colors"
          onClick={() => {
            resetField(name);
            setIsEditable(false);
          }}
        >
          <IoClose className="text-gray-dark" />
        </button>
      ) : (
        <button
          className="absolute left-16 hover:bg-slate-100 p-1 rounded-full transition-colors"
          onClick={() => {
            setIsEditable(true);
          }}
        >
          <FaPen className="text-primary" />
        </button>
      )}
    </span>
  );
};

const DetailsCell = ({ value, row, classname }: any) => {
  const [showModal, setShowModal] = useState(false);

  const isEmpty = Object.keys(value).length === 0;

  const details = value?.details || [];
  const new_value = details[0]?.new_value;
  const old_value = details[0]?.old_value;

  return !isEmpty ? (
    <div className="flex items-center gap-2">
      <span>
        {DefaultCellWithParser(x => format(x?.date?.toString(), 'dd/MM/yyyy'))({
          value,
          row,
          className: `pl-2 ${classname}`
        })}
      </span>

      <button
        onClick={() => setShowModal(true)}
        className="hover:text-gray-dark"
      >
        <FaInfoCircle className="text-primary" />
      </button>

      {showModal && (
        <Modal isOpen={showModal} setIsOpen={setShowModal}>
          <div className="p-6">
            <Label
              className="text-primary"
              name="Detalhes de edição"
              id="details"
              noBorder
            />
            <div className="border-b border-primary mb-4"></div>

            <div className="flex flex-wrap gap-2 min-w-[650px]">
              <div>
                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Executor da edição:{' '}
                  <span className="font-bold">{value?.user}</span>
                </p>
                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Data de edição:{' '}
                  <span className="font-bold">
                    {format(value?.date?.toString(), 'dd/MM/yyyy HH:mm')}
                  </span>
                </p>
              </div>

              <div>
                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Valor novo: <span className="font-bold">{new_value}</span>
                </p>

                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Valor antigo:{' '}
                  <span className="font-bold">
                    {old_value ? old_value : '-'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  ) : null;
};
