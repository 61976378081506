import Modal from '@/components/atoms/Modal';
import { UserSettingsDTO } from '../types';
import { useState } from 'react';
import { Label } from '@/components/atoms/Label';
import Checkbox from '@/components/atoms/Checkbox';
import Button from '@/components/atoms/Button';

export const ModalAddUser = ({
  isOpen,
  setIsOpen,
  handleAddOrUpdateUsers
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleAddOrUpdateUsers: (users: UserSettingsDTO[]) => Promise<void>;
}) => {
  const [newUserSettings, setNewUserSettings] = useState<UserSettingsDTO>({
    email: '',
    active: true,
    receive_troughput_email: false,
    receive_pressure_email: false,
    receive_elevation_email: false,
    receive_no_pressure_measure_email: false
  });
  const handleClearNewUserSettings = () => {
    setNewUserSettings({
      email: '',
      active: true,
      receive_troughput_email: false,
      receive_pressure_email: false,
      receive_elevation_email: false,
      receive_no_pressure_measure_email: false
    });
  };
  return (
    <Modal
      className="max-w-none w-fit relative"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="flex flex-col p-8 gap-2">
        <div>
          <Label
            id="Adicionar novo usuário"
            className="w-full text-primary"
            name="Adição de destinatários de alertas"
            noBorder
          />

          <div className="w-full border-b border-primary" />
        </div>

        <div>
          <input
            type="email"
            title="E-mail"
            placeholder="Email"
            className="w-full p-2 border border-gray-300 rounded"
            value={newUserSettings.email}
            onChange={e =>
              setNewUserSettings(prev => ({
                ...prev,
                email: e.target.value
              }))
            }
          />
        </div>
        <div className="flex flex-row gap-2">
          <Checkbox
            id="new-checkbox-throughput"
            label="Vazão"
            checked={newUserSettings.receive_troughput_email}
            onChange={e =>
              setNewUserSettings(prev => ({
                ...prev,
                receive_troughput_email: e.target.checked
              }))
            }
          />
          <Checkbox
            id="new-checkbox-pressure"
            label="Pressão"
            checked={newUserSettings.receive_pressure_email}
            onChange={e =>
              setNewUserSettings(prev => ({
                ...prev,
                receive_pressure_email: e.target.checked
              }))
            }
          />
          <Checkbox
            id="new-checkbox-elevation"
            label="Métodos de elevação bombeados"
            checked={newUserSettings.receive_elevation_email}
            onChange={e =>
              setNewUserSettings(prev => ({
                ...prev,
                receive_elevation_email: e.target.checked
              }))
            }
          />
          <Checkbox
            id="new-checkbox-noMeasurement"
            label="Poço sem medição de pressão"
            checked={newUserSettings.receive_no_pressure_measure_email}
            onChange={e =>
              setNewUserSettings(prev => ({
                ...prev,
                receive_no_pressure_measure_email: e.target.checked
              }))
            }
          />
        </div>
        <div className="flex w-full gap-2 justify-between h-fit py-6 px-8">
          <Button
            title="Cancelar"
            onClick={() => {
              setIsOpen(false);
              handleClearNewUserSettings();
            }}
            className="h-10 w-40 bg-transparent border-primary border-2 hover:bg-primary/10"
            classNameSpan="text-primary font-medium"
          />

          <Button
            title="Salvar"
            onClick={async () => {
              await handleAddOrUpdateUsers([newUserSettings]);
              setIsOpen(false);
              handleClearNewUserSettings();
            }}
            classNameSpan="h-6 w-32 font-medium"
          />
        </div>
      </div>
    </Modal>
  );
};
