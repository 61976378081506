import { twMerge } from 'tailwind-merge';

const CustomStyleCell = ({ appendClassName }: { appendClassName: string }) => {
  const cell = ({ value, className }: any) => {
    return (
      <div className={twMerge(className, appendClassName)}>{value || '-'}</div>
    );
  };

  return cell;
};

export default CustomStyleCell;
