import { Column, useTable } from 'react-table';
import { twMerge } from 'tailwind-merge';

interface SimpleTableProps {
  columns: Column[];
  data: object[];
  className?: string;
}

const SimpleTable = ({ columns, data, className }: SimpleTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<object>({
      columns,
      data
    });

  return (
    <table {...getTableProps()} className={twMerge('w-full', className)}>
      <thead>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map(column => {
                const { key, ...restColumn } = column.getHeaderProps({
                  style: {
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth
                  }
                });
                return (
                  <th key={key} {...restColumn}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <tr key={key} {...restRowProps}>
              {row.cells.map(cell => {
                const { key, ...restCellProps } = cell.getCellProps({
                  style: {
                    width: cell.column.width,
                    minWidth: cell.column.minWidth,
                    maxWidth: cell.column.maxWidth
                  }
                });
                return (
                  <td key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SimpleTable;
