import {
  getConjugateLine,
  getLocation,
  getOilwellField,
  getOilwellSatellites,
  getOilwellStatus
} from '../../services/oilwells.services';
import { objectify } from './WorflowTests';

const oilwellTypeValues = ['Produtor', 'Injetor', 'Captação', 'NA'];

const fluidValues = ['Água', 'Óleo', 'GNA', 'NA'].map(objectify);

const elevationMethodValues = [
  'GLC',
  'GLI',
  'S',
  'BCS',
  'BCP',
  'BM',
  'BPZ',
  'CDZ',
  'NA'
].map(objectify);

const fieldValues = [
  'Furado',
  'Anambé',
  'Fazenda Matinha',
  'Fazenda Santa Rosa',
  'Conceição',
  'Paru',
  'São Miguel dos Campos',
  'Cidade de São Miguel dos Campos',
  'Arapaçu',
  'Pilar',
  'Quererá'
];

const selectMapper = (it: any) => ({ label: it, value: it });

export const injectionTypes = [
  'Gas Rec. Sec',
  'Rec. Sec',
  'Descarte',
  'NA'
].map(selectMapper);

export const waterBranch = [
  'Pilar',
  'Pilar - Injeção Descentralizada',
  'Furado - Baixa/Alta',
  'Furado - FU-0085'
].map(selectMapper);

export const targetInstallationCode = [
  '10189',
  '10121',
  '10122',
  '10123',
  '10124',
  '10125'
].map(selectMapper);

const poleValues = ['PAL', 'PTS'];

const poles = poleValues.map(selectMapper);

const oilwellType = oilwellTypeValues.map(selectMapper);

const fields = fieldValues.map(selectMapper);

const status = [
  {
    value: 'Operando',
    label: 'Produzindo'
  },
  {
    value: 'Operando',
    label: 'Operando'
  },
  {
    value: 'Fechado',
    label: 'Fechado'
  },
  {
    value: 'Abandonado',
    label: 'Abandonado'
  }
];

const getOilFieldAsync = async (search: string) => {
  const fields = await getOilwellField();

  return fields
    .filter((it: any) => (search ? it.label.includes(search) : true))
    .map((it: any) => ({
      value: it.name,
      label: it.name
    }));
};

const getLocationAsync = async (search: string) => {
  const location = await getLocation();

  return location
    .filter((it: any) => (search ? it.label.includes(search) : true))
    .map((it: any) => ({
      value: it.name,
      label: it.name
    }));
};

const getConjugateLineAsync = async (search: string) => {
  const conjugate = await getConjugateLine();

  return conjugate
    .filter((it: any) => (search ? it.label.includes(search) : true))
    .map((it: any) => ({
      value: it.name,
      label: it.name
    }));
};

export const getSateliteAsync = async (search: string) => {
  const sat = await getOilwellSatellites();

  return sat
    .filter((it: any) => (search ? it.label.includes(search) : true))
    .map((it: any) => ({
      value: it.name,
      label: it.name
    }));
};

export const getStatusAsync = async (search: string) => {
  const status = await getOilwellStatus();

  const uniqueStatus = Array.from(
    new Set(status.map((it: any) => it.operational))
  );

  return uniqueStatus
    .filter((it: any) => (search ? it.includes(search) : true))
    .map((it: any) => ({
      value: it,
      label: it
    }));
};

export const oilwellsFilters = [
  {
    key: 'oilwellType',
    name: 'Tipo de poço',
    values: oilwellType,
    asyncFn: undefined
  },
  { key: 'pole', name: 'Polo', values: poles },
  { key: 'fluid', name: 'Fluido', values: fluidValues },
  {
    key: 'elevationMethod',
    name: 'Método de Elevação',
    values: elevationMethodValues
  },
  { key: 'field', name: 'Campo', values: fields, asyncFn: getOilFieldAsync },
  {
    key: 'location',
    name: 'Locação',
    values: [],
    asyncFn: getLocationAsync
  },
  {
    key: 'conjugate',
    name: 'Linha Conjugada',
    values: [],
    asyncFn: getConjugateLineAsync
  },
  {
    key: 'satelite',
    name: 'Satélite',
    values: [],
    asyncFn: getSateliteAsync
  }
];
