import Button from '@/components/atoms/Button';
import { Label } from '@/components/atoms/Label';
import Modal from '@/components/atoms/Modal';
import { format } from 'date-fns';
import { AlertConfigChangeDTO } from '../types';
import { numberFormatter2 } from '@/utils/utils';

interface SubmitSummaryModalProps {
  changes: AlertConfigChangeDTO[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  alertTypeLabelResource: Record<string, string>;
}

const SubmitSummaryModal: React.FC<SubmitSummaryModalProps> = ({
  changes,
  isOpen,
  onClose,
  onConfirm,
  alertTypeLabelResource
}) => {
  return (
    <Modal
      className="max-w-none w-fit relative"
      isOpen={isOpen}
      setIsOpen={onClose}
    >
      <div className="px-8 py-4">
        <Label
          id="Alterações nos Alertas"
          className="text-primary border-b border-primary pt-3"
          name="Alterações nos Alertas"
          noBorder
        />

        <h3 className="font-medium text-slate-800 py-2">
          Ao prosseguir com esta ação, as seguintes alterações serão editadas a
          partir da data atual {format(new Date(), 'dd/MM/yyyy')}
        </h3>

        <div className="grid grid-cols-4 gap-2">
          {changes.map((change, index) => (
            <div key={index} className="contents">
              <span className="font-medium text-slate-800 text-left before:content-['•'] before:mr-2">
                {change.oilwell}
              </span>
              <span className="font-medium text-slate-800 text-left">
                {alertTypeLabelResource[change.alert_type]}
              </span>
              <span
                className={`${getCriticalityColor(change.criticality)} text-left`}
              >
                {translateCriticality(change.criticality)}
              </span>
              <span className="text-slate-600 text-left">
                {change.old_value
                  ? `${change.criticality === 2 ? '>' : '≤'} ${numberFormatter2().format(change.old_value)} → ${change.criticality === 2 ? '>' : '≤'} ${numberFormatter2().format(change.new_value)}`
                  : `${change.criticality === 2 ? '>' : '≤'} ${numberFormatter2().format(change.new_value)}`}
              </span>
            </div>
          ))}
        </div>

        <div className="flex w-full gap-2 justify-between h-fit py-6 px-8">
          <Button
            title="Cancelar"
            onClick={onClose}
            className="h-10 w-40 bg-transparent border-primary border-2 hover:bg-primary/10"
            classNameSpan="text-primary font-medium"
          />

          <Button
            title="Confirmar"
            onClick={onConfirm}
            classNameSpan="h-6 w-32 font-medium"
          />
        </div>
      </div>
    </Modal>
  );
};

const translateCriticality = (criticality: number): string => {
  const translations = {
    2: 'Vermelho',
    3: 'Verde'
  };

  return translations[criticality as keyof typeof translations];
};

const getCriticalityColor = (criticality: number): string => {
  const colors = {
    2: 'text-[#A60000]',
    3: 'text-[#0C8A6C]'
  };

  return colors[criticality as keyof typeof colors] || 'text-slate-500';
};

export default SubmitSummaryModal;
