import Tabs from '@/components/atoms/Tab';
import { FieldValues } from 'react-hook-form';
import AlertVariablesTab from './Components/AlerVariables/AlertVariablesTab';
import { AlertVariablesDTO, UserSettingsDTO } from './Components/types';
import AlertUserSettings from './Components/Users/UserSettingsTab';
import { getAllOilwells } from '@/services/oilwells.services';
import api from '@/services/apiPortal';
import { toast } from 'react-toastify';
import { useAccount } from '@azure/msal-react';

const OILWELLS_LIMIT = 50;

const AlertSettings = () => {
  const fetchOilwells = async (fieldValues: FieldValues): Promise<string[]> => {
    const { search, ...filters } = fieldValues;
    const { data } = await getAllOilwells(0, OILWELLS_LIMIT, search, filters);
    return data?.map((oilwell: any) => oilwell.name);
  };

  const user = useAccount();

  // pegar o email do usuário logado
  const author_email = user?.username;

  const fetchAlertVariables = async (
    oilwells: string[]
  ): Promise<AlertVariablesDTO> => {
    const { data } = await api.get(`/oilwells`, {
      params: {
        route: 'alert_config',
        oilwells: oilwells.join(',')
      }
    });
    return data;
  };

  const submitAlertVariables = async (
    fieldValues: FieldValues
  ): Promise<void> => {
    try {
      const _ = await api.post(`/oilwells/update-alerts-config`, {
        data: fieldValues,
        user_email: author_email
      });
      toast.success('Configurações atualizadas com sucesso');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao atualizar configurações: ${error.message}`);
      } else {
        toast.error('Erro ao atualizar configurações');
      }
    }
    return;
  };

  const fetchUsers = async (
    name: string,
    page: number,
    perPage: number
  ): Promise<{
    data: UserSettingsDTO[];
    pagy: { count: number; page: number };
  }> => {
    const { data } = await api.get(`/oilwells`, {
      params: {
        route: 'alert_user',
        email: name,
        page,
        perPage
      }
    });
    const users = data.data?.map((user: any) => {
      return {
        email: user.email,
        receive_troughput_email: user.receive_troughput_email,
        receive_pressure_email: user.receive_pressure_email,
        receive_elevation_email: user.receive_elevation_email,
        receive_no_pressure_measure_email:
          user.receive_no_pressure_measure_email
      };
    });
    return { data: users, pagy: data.pagy };
  };

  const handleAddOrUpdateUsers = async (
    users: UserSettingsDTO[]
  ): Promise<void> => {
    try {
      const _ = await api.post(`/oilwells/alerts/update-alert-user`, {
        data: users,
        user_email: author_email
      });
      toast.success('Configurações atualizadas com sucesso');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao atualizar configurações: ${error.message}`);
      } else {
        toast.error('Erro ao atualizar configurações');
      }
    }
    return;
  };

  const handleDeletion = async (email: string) => {
    const users: UserSettingsDTO[] = [
      {
        email,
        active: false,
        receive_troughput_email: false,
        receive_pressure_email: false,
        receive_elevation_email: false,
        receive_no_pressure_measure_email: false
      }
    ];

    try {
      const _ = await api.post(`/oilwells/alerts/update-alert-user`, {
        data: users,
        user_email: email
      });
      toast.success('Configurações atualizadas com sucesso');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao atualizar configurações: ${error.message}`);
      } else {
        toast.error('Erro ao atualizar configurações');
      }
    }
    return;
  };

  const tabs = [
    {
      id: 0,
      label: 'Alteração de Variáveis',
      content: (
        <AlertVariablesTab
          fetchOilwells={fetchOilwells}
          fetchAlertVariables={fetchAlertVariables}
          submitAlertVariables={submitAlertVariables}
        />
      )
    },
    {
      id: 1,
      label: 'Alteração de Destinatários de Alertas',
      content: (
        <AlertUserSettings
          fetchUsers={fetchUsers}
          handleAddOrUpdateUsers={handleAddOrUpdateUsers}
          handleDeletion={handleDeletion}
        />
      )
    }
  ];

  return <Tabs tabs={tabs} />;
};

export default AlertSettings;
