import { CustomFieldHash, GridSchema } from '@/types/redmine';
import { twMerge } from 'tailwind-merge';
import { DefaultCell } from '../components/atoms/table/DefaultCell';
import { Header } from '../components/atoms/table/Header';
import { NumberCellFC } from '../components/atoms/table/NumberCell';
import { PasswordCellFC } from '../components/atoms/table/PasswordCell';

const HourCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs">
      {value?.split(' ')?.[1] ?? value}
    </span>
  );
};

export const NumberCell = ({
  value,
  row: {
    original: { id, isSummary },
    index
  }
}: any) => {
  if (value === '-' || value === null || value === undefined || value === '') {
    return (
      <span
        className={twMerge(
          `text-[#191919] text-xs whitespace-nowrap`,
          isSummary && 'summary font-bold'
        )}
      >
        -
      </span>
    );
  }
  const newValue = !Number.isNaN(Number(value))
    ? new Intl.NumberFormat('pt-BR').format(value !== '-' ? (value ?? 0) : 0)
    : value;

  return (
    <span
      key={`${id}Pressure${index}`}
      className={twMerge(
        'text-[#191919] text-xs whitespace-nowrap',
        isSummary ? 'font-bold summary' : ''
      )}
    >
      {newValue ? newValue : '-'}
    </span>
  );
};

export const getField = (data: any, fieldId: number) => {
  if (data) {
    return data?.issues?.[0]?.custom_fields?.find(
      (field: any) => field.id === fieldId
    )?.value;
  }
  return null;
};

export const getFieldByArray = (data: any[] = [], fieldId: number) =>
  data?.find((field: any) => field.id === fieldId)?.value;

export const tablefy = (
  items: any,
  dateCols?: string[],
  numCols?: string[],
  passwordCols?: string[],
  num2f?: string[],
  secondary?: boolean,
  terciary?: boolean,
  fourthary?: boolean
) =>
  Object.keys(items).map(key => ({
    Header: (
      <Header
        text={key}
        id={key}
        className="h-auto"
        secondary={secondary}
        terciary={terciary}
        fourthary={fourthary}
      />
    ),
    accessor: items[key],
    Cell:
      dateCols && dateCols.includes(key)
        ? HourCell
        : numCols?.includes(key)
          ? NumberCell
          : passwordCols?.includes(key)
            ? PasswordCellFC
            : num2f?.includes(key)
              ? NumberCellFC(2)
              : DefaultCell,
    minWidth: 120,
    width: undefined
  }));

const getCell = (type: string) => {
  switch (type) {
    case 'int':
      return NumberCell;
    case 'float':
      return NumberCellFC(2);
    default:
      return DefaultCell;
  }
};

export const tablefyWithCustomFields = (
  columns: (string | number)[],
  cfs: CustomFieldHash,
  headerClassName?: string
) => {
  return columns.map(it => {
    return {
      Header: (
        <Header
          id={cfs[it]?.name}
          text={cfs[it]?.name}
          className={headerClassName}
        />
      ),
      accessor: `${it}`,
      Cell: getCell(cfs[it]?.type),
      minWidth: 120,
      width: undefined
    };
  });
};

export const tablefyWithGridSchema = (
  gridSchema: GridSchema[],
  headerClassName?: string
) => {
  return gridSchema.map(it => {
    return {
      Header: (
        <Header
          id={it.name}
          text={it.displayed_label}
          className={headerClassName}
        />
      ),
      accessor: `${it.grid_column_id}`,
      Cell: getCell(it.type),
      minWidth: 120,
      width: undefined
    };
  });
};
