import Button from '@/components/atoms/Button';
import { Label } from '@/components/atoms/Label';
import SearchBar from '@/components/atoms/SearchBar';
import Spinner from '@/components/atoms/Spinner';
import PageNavigation from '@/components/molecules/PageNavigation';
import SimpleTable from '@/components/molecules/SimpleTable';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FaPen, FaSave } from 'react-icons/fa';
import { IoAdd } from 'react-icons/io5';
import { UserSettingsDTO } from '../types';
import { ModalAddUser } from './ModalAddUser';
import { getUserColumns } from './Tables';
import { queryClient } from '@/App';

const ITEMS_PER_PAGE = 10;

const USER_SETTINGS_QUERY_KEY = 'userSettings';

interface UserSettingsTabProps {
  fetchUsers: (
    name: string,
    page: number,
    perPage: number
  ) => Promise<{
    data: UserSettingsDTO[];
    pagy: { count: number; page: number };
  }>;
  handleAddOrUpdateUsers: (users: UserSettingsDTO[]) => Promise<void>;
  handleDeletion: (email: string) => Promise<void>;
}

const AlertUserSettings = ({
  fetchUsers,
  handleAddOrUpdateUsers,
  handleDeletion
}: UserSettingsTabProps) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect,
  //but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.
  //Error Component Stack
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [search]);

  const {
    data: alertUsersSettings = { data: [], pagy: { count: 0, page: 1 } },
    isLoading
  } = useQuery<{
    data: UserSettingsDTO[];
    pagy: { count: number; page: number };
  }>({
    queryKey: [USER_SETTINGS_QUERY_KEY, debouncedSearch, page],
    queryFn: () => fetchUsers(debouncedSearch, page, ITEMS_PER_PAGE),
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const [dirtyValues, setDirtyValues] = useState<UserSettingsDTO[]>([]);

  const handleUserDeletion = async (email: string) => {
    await handleDeletion(email);
    await clearChanges();
  };

  useEffect(() => {
    if (alertUsersSettings?.data) {
      setDirtyValues(alertUsersSettings.data);
    }
  }, [alertUsersSettings?.data]);

  const clearChanges = async () => {
    await queryClient.invalidateQueries([USER_SETTINGS_QUERY_KEY]);
  };

  return (
    <>
      <div className="flex flex-col bg-white rounded-lg p-4 gap-2 shadow">
        <div className="flex flex-row justify-between items-center">
          <Label
            id="Alteração de Destinatários de Alertas"
            className="w-1/2 text-primary"
            name="Alteração de Destinatários de Alertas"
            noBorder
          />

          <div className="w-1/2 flex flex-row gap-2 items-center">
            <SearchBar
              placeholder="Pesquisar..."
              onChange={e => setSearch(e.target.value)}
            />
            {isEditing ? (
              <Button
                title="Salvar"
                className="w-32 h-8"
                icon={<FaSave />}
                onClick={async () => {
                  setIsEditing(false);
                  await handleAddOrUpdateUsers(
                    getUserSettingsChanges(alertUsersSettings.data, dirtyValues)
                  );
                  await clearChanges();
                }}
              />
            ) : (
              <Button
                title="Editar"
                className="w-32 h-8 bg-white text-primary border border-primary"
                icon={<FaPen />}
                onClick={() => setIsEditing(true)}
              />
            )}
            <Button
              title="Adicionar"
              className="w-32 h-8"
              icon={<IoAdd />}
              onClick={() => setIsModalOpen(true)}
            />
          </div>
          <ModalAddUser
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleAddOrUpdateUsers={async users => {
              await handleAddOrUpdateUsers(users);
              await clearChanges();
            }}
          />
        </div>

        {isLoading ? (
          <Spinner size={24} />
        ) : (
          alertUsersSettings.data.length > 0 && (
            <SimpleTable
              columns={getUserColumns(
                dirtyValues,
                setDirtyValues,
                handleUserDeletion,
                isEditing
              )}
              data={alertUsersSettings.data}
            />
          )
        )}
      </div>
      <PageNavigation
        currentPage={page}
        setCurrentPage={setPage}
        totalPages={Math.ceil(alertUsersSettings.pagy.count / ITEMS_PER_PAGE)}
        perPage={ITEMS_PER_PAGE}
      />
    </>
  );
};

const getUserSettingsChanges = (
  userSettingsData: UserSettingsDTO[],
  dirtyValues: UserSettingsDTO[]
): UserSettingsDTO[] => {
  const changes: UserSettingsDTO[] = [];
  dirtyValues.forEach((dirtyValue, index) => {
    const originalValue = userSettingsData[index];

    if (
      dirtyValue.receive_troughput_email !==
        originalValue.receive_troughput_email ||
      dirtyValue.receive_pressure_email !==
        originalValue.receive_pressure_email ||
      dirtyValue.receive_elevation_email !==
        originalValue.receive_elevation_email ||
      dirtyValue.receive_no_pressure_measure_email !==
        originalValue.receive_no_pressure_measure_email
    ) {
      const change: UserSettingsDTO = { email: dirtyValue.email };

      if (
        dirtyValue.receive_troughput_email !==
        originalValue.receive_troughput_email
      ) {
        change.receive_troughput_email = dirtyValue.receive_troughput_email;
      }
      if (
        dirtyValue.receive_pressure_email !==
        originalValue.receive_pressure_email
      ) {
        change.receive_pressure_email = dirtyValue.receive_pressure_email;
      }
      if (
        dirtyValue.receive_elevation_email !==
        originalValue.receive_elevation_email
      ) {
        change.receive_elevation_email = dirtyValue.receive_elevation_email;
      }
      if (
        dirtyValue.receive_no_pressure_measure_email !==
        originalValue.receive_no_pressure_measure_email
      ) {
        change.receive_no_pressure_measure_email =
          dirtyValue.receive_no_pressure_measure_email;
      }

      changes.push(change);
    }
  });

  return changes;
};

export default AlertUserSettings;
