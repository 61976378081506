import { useEffect, useState } from 'react';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import H1 from '../../components/atoms/H1';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import TabsItem from '../../components/atoms/TabsItem';
import PageNavigation from '../../components/molecules/PageNavigation';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';
import {
  elevationAlertFilters,
  getFilterByElevationMethod
} from '../../constants/filters/AlertFilters';
import { ElevationAlertsAtom } from '../../state/alerts.atom';

import elevationAlertCols, {
  defaultElevationCols
} from '@/constants/tableColumns/alerts/elevationAlerts';
import useElevationAlerts, {
  elevationMethodsArray
} from '@/hooks/Alerts/useElevationAlerts';

export const ElevationAlerts = () => {
  const [tab, setTab] = useState(0);
  const [elevationMethod, setElevationMethod] = useState(0);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [alertFields, setAlertFields] = useState<any>([]);
  const filterButtons = ['24H', '48H', '7D'].map((it, idx) => ({
    label: it,
    onClick: () => {
      setTab(idx);
    }
  }));

  const [filters, setFilters] = useState<
    {
      key: string;
      name: string;
      values: { label: string; value: string | number }[];
      asyncFn?: any;
    }[]
  >([]);

  useEffect(() => {
    const newFilters = getFilterByElevationMethod(elevationMethod);
    setFilters(newFilters);
  }, [elevationMethod]);

  const elevationMethodButtons = elevationMethodsArray.map((it, idx) => ({
    label: it.toUpperCase(),
    onClick: () => {
      setElevationMethod(idx);
      setFilteredData({
        ...filteredData,
        alertType: []
      });
    }
  }));

  const mapIntervalToDays = new Map<string, string>([
    ['1', '24h'],
    ['2', '48h'],
    ['7', '7d']
  ]);
  const {
    data,
    isFetching,
    isLoading,
    handleFilter,
    perPage,
    currentPage,
    filteredData,
    setFilteredData,
    setCurrentPage
  } = useElevationAlerts(tab, elevationMethod);

  return (
    <>
      <div className="flex gap-2 items-center">
        {Object.values(elevationMethodButtons).map((it, idx) => (
          <TabsItem key={it.label} {...it} menu={elevationMethod} idx={idx} />
        ))}
      </div>
      <PageWrapper className={'p-0'}>
        <div className="w-full flex flex-col relative pt-4 px-2 rounded-lg bg-white mt-2 pb-6">
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="w-[203px] resize-none max-w-none -ml-5 only-print"
          />

          <H1 className="px-4 whitespace-nowrap">
            <span className="flex gap-4 ">
              Métodos de Elevação
              {isFetching ? <Spinner size={18} /> : null}
            </span>

            <div className="flex gap-2 items-center">
              <span className="text-base text-primary">
                Periodo Anterior de Comparação:
              </span>
              {Object.values(filterButtons).map((it, idx) => (
                <TabsItem key={it.label} {...it} menu={tab} idx={idx} />
              ))}
            </div>
          </H1>
          {isLoading ? (
            <Spinner size={24} />
          ) : (
            <section className="flex flex-col rounded-lg px-2 py-3 pb-0 bg-white">
              <div className="flex w-full overflow-y-auto max-h-[calc(100vh-220px)] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                {!data || data?.data?.length < 1 ? (
                  <EmptyMessage
                    message="Sem dados disponíveis"
                    className="min-h-[2rem] w-min"
                  />
                ) : (
                  <Table
                    columns={defaultElevationCols.concat(
                      elevationAlertCols[elevationMethod]
                    )}
                    data={data?.data}
                    headerClassName="sticky top-0 bg-white z-10"
                  />
                )}
              </div>
              {!!data && (
                <PageNavigation
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  className="mt-2"
                  totalPages={Math.ceil(data?.meta?.count / perPage)}
                  perPage={perPage}
                />
              )}
            </section>
          )}
        </div>

        <SideFilter
          atom={ElevationAlertsAtom}
          filters={filters}
          applyChanges={handleFilter}
        />
      </PageWrapper>
    </>
  );
};
