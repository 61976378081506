import Button from '@/components/atoms/Button';
import H1 from '@/components/atoms/H1';
import { Label } from '@/components/atoms/Label';
import Spinner from '@/components/atoms/Spinner';
import TogglePanel from '@/components/atoms/TogglePanel';
import OilwellFiltersCard, {
  Filter
} from '@/components/molecules/OilwellFiltersCard';
import {
  elevationMethodValues,
  oilwellFluidType,
  oilwellStation
} from '@/constants/filters/AlertFilters';
import {
  getSateliteAsync,
  getStatusAsync
} from '@/constants/filters/OilWellsTable';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import {
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn
} from 'react-hook-form';
import {
  AlertConfigChangeDTO,
  AlertConfigDTO,
  AlertSection,
  alertSectionLabelResource,
  AlertVariableDTO,
  AlertVariables,
  AlertVariablesDTO,
  getAlertTypeLabelResource,
  mapAlertVariablesDTOToAlertVariables,
  SectionConfig
} from '../types';
import { AlertTables } from './AlertTables';
import SubmitSummaryModal from './SubmitSummaryModal';
import { queryClient } from '@/App';
import { formatStringToNumber } from '@/utils/utils';
import useFiltersCardImage from '@/assets/icons/useFiltersCard.png';

const CARD_FILTERS_NAME = 'card-filters-alert-variables';

const ALERT_VARIABLES_QUERY_KEY = 'alertVariables';

const CARD_FILTER_SELECT_SCHEMA: Filter[] = [
  {
    key: 'fluid',
    name: 'Fluido',
    values: oilwellFluidType
  },
  {
    key: 'status',
    name: 'Status',
    values: [],
    asyncFn: getStatusAsync
  },
  {
    key: 'field',
    name: 'Campo',
    values: oilwellStation
  },
  {
    key: 'elevationMethod',
    name: 'Método de Elevação',
    values: elevationMethodValues
  },
  {
    key: 'satelite',
    name: 'Satélite',
    values: [],
    asyncFn: getSateliteAsync
  }
];

interface OilwellsSectionProps {
  alertVariables: AlertVariables;
  methods: UseFormReturn<any, any, undefined>;
  isLoading: boolean;
}

const AlertVariablesTab = ({
  fetchOilwells,
  fetchAlertVariables,
  submitAlertVariables
}: {
  fetchOilwells: (data: FieldValues) => Promise<string[]>;
  fetchAlertVariables: (oilwells: string[]) => Promise<AlertVariablesDTO>;
  submitAlertVariables: (data: FieldValues) => Promise<void>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [oilwells, setOilwells] = useState<string[]>([]);
  const [changes, setChanges] = useState<AlertConfigChangeDTO[]>([]);

  const { data: alertVariables = {}, isLoading } = useQuery({
    queryKey: [ALERT_VARIABLES_QUERY_KEY, oilwells],
    queryFn: () =>
      fetchAlertVariables(oilwells).then(x =>
        mapAlertVariablesDTOToAlertVariables(x)
      ),
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: oilwells.length > 0
  });

  const methods = useForm({
    defaultValues: alertVariables,
    values: alertVariables,
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true
    }
  });

  const clearChanges = async () => {
    methods.reset();
    await queryClient.invalidateQueries([ALERT_VARIABLES_QUERY_KEY]);
  };

  const handleShowModal = methods.handleSubmit(data => {
    const diff = getAlertVariablesChanges(alertVariables, data);
    setChanges(diff);
    setShowModal(true);
  });

  const handleSave = methods.handleSubmit(async data => {
    const diff = getAlertVariablesChanges(alertVariables, data);
    await submitAlertVariables(diff);
    setShowModal(false);
    await clearChanges();
  });

  return (
    <>
      <H1>Parametrização por Poços</H1>
      <div className="flex flex-col gap-2 p-4">
        <OilwellFiltersCard
          name={CARD_FILTERS_NAME}
          filters={CARD_FILTER_SELECT_SCHEMA}
          fetchOilwells={fetchOilwells}
          onOilwellCheckboxChange={setOilwells}
        />

        {oilwells.length > 0 ? (
          <OilwellsSection
            alertVariables={alertVariables}
            methods={methods}
            isLoading={isLoading}
          />
        ) : (
          <div className="flex flex-col gap-2 p-4">
            <img
              src={useFiltersCardImage}
              alt="Use filters card"
              className="mx-auto"
            />
          </div>
        )}
      </div>

      <div className="flex justify-end items-start gap-x-6">
        <button
          className="text-primary hover:text-gray-800 transition-colors"
          onClick={() => methods.reset()}
        >
          Cancelar
        </button>
        <Button
          title="Salvar"
          className="w-32 h-6 text-sm"
          onClick={handleShowModal}
        />
      </div>

      {showModal && (
        <SubmitSummaryModal
          changes={changes}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={handleSave}
          alertTypeLabelResource={getAlertTypeLabelResource(alertVariables)}
        />
      )}
    </>
  );
};

const OilwellsSection = ({
  alertVariables,
  methods,
  isLoading
}: OilwellsSectionProps) => {
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <Spinner />
        </div>
      ) : (
        <FormProvider {...methods}>
          <Label
            id={1}
            className="text-primary border-b border-[#c1c1c1] pt-3"
            name="Alteração de Variáveis"
            noBorder
          />

          {Object.entries(alertVariables).map(
            ([oilwell, oilwellAlertSections]) => (
              <div key={oilwell} className="flex flex-col gap-2">
                <Label
                  id={oilwell}
                  className="text-primary border-b border-[#c1c1c1] pt-3"
                  name={oilwell}
                  noBorder
                />
                {Object.entries(oilwellAlertSections).map(
                  ([alertSection, alertSectionValues]) => (
                    <TogglePanel
                      key={`${oilwell}.${alertSection}`}
                      buttonClassName="py-4 bg-white rounded-lg hover:bg-white hover:ring-1 hover:ring-persian-blue-500"
                      titleComponent={_ => (
                        <div className="flex items-center gap-2">
                          <span>
                            {
                              alertSectionLabelResource[
                                alertSection as AlertSection
                              ]
                            }
                          </span>
                        </div>
                      )}
                    >
                      <AlertTables
                        oilwell={oilwell}
                        section={alertSection as AlertSection}
                        defaultValues={alertSectionValues}
                        methods={methods}
                      />
                    </TogglePanel>
                  )
                )}
              </div>
            )
          )}
        </FormProvider>
      )}
    </>
  );
};

const getAlertVariablesChanges = (
  oldAlertVariables: AlertVariables,
  newAlertVariables: AlertVariables
) => {
  return Object.entries(newAlertVariables).flatMap(
    ([oilwell, newAlertVariable]) => {
      return Object.entries(newAlertVariable).flatMap(
        ([alertType, newAlertConfig]) => {
          const oldAlertConfig =
            oldAlertVariables[oilwell]?.[alertType as keyof AlertVariableDTO];

          return getSessionConfigChangeList(
            oilwell,
            oldAlertConfig,
            newAlertConfig
          );
        }
      );
    }
  );
};

const getSessionConfigChangeList = (
  oilwell: string,
  oldAlertConfig: SectionConfig,
  newAlertConfig: SectionConfig
): AlertConfigChangeDTO[] => {
  const result: AlertConfigChangeDTO[] = [];

  result.push(
    ...getAlertConfigTwoLevelsChangeList(
      oilwell,
      oldAlertConfig?.ratio_alert || [],
      newAlertConfig?.ratio_alert || []
    )
  );

  result.push(
    ...getAlertConfigThreeLevelsChangeList(
      oilwell,
      oldAlertConfig?.percentage_change_alert || [],
      newAlertConfig?.percentage_change_alert || []
    )
  );

  result.push(
    ...getAlertConfigThreeLevelsChangeList(
      oilwell,
      oldAlertConfig?.absolute_difference_alert || [],
      newAlertConfig?.absolute_difference_alert || []
    )
  );

  return result;
};

const getAlertConfigThreeLevelsChangeList = (
  oilwell: string,
  oldAlertVariables: AlertConfigDTO[],
  newAlertVariables: AlertConfigDTO[]
) => {
  const result: AlertConfigChangeDTO[] = [];

  newAlertVariables.forEach((newVariable, index) => {
    const oldVariable = oldAlertVariables[index];

    if (!oldVariable) {
      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality: 3,
        new_value: formatStringToNumber(newVariable.green)
      });

      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality: 2,
        new_value: formatStringToNumber(newVariable.red)
      });
      return;
    }

    (['red', 'green'] as const).forEach(color => {
      // Cast to string to avoid comparing numbers with strings
      const new_value = formatStringToNumber(newVariable[color]);
      const old_value = formatStringToNumber(oldVariable[color]);

      if (new_value === old_value) {
        return;
      }

      const criticality = color === 'green' ? 3 : 2;

      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality,
        old_value: old_value,
        new_value: new_value
      });
    });
  });

  return result;
};

const getAlertConfigTwoLevelsChangeList = (
  oilwell: string,
  oldAlertVariables: AlertConfigDTO[],
  newAlertVariables: AlertConfigDTO[]
) => {
  const result: AlertConfigChangeDTO[] = [];

  newAlertVariables.forEach((newVariable, index) => {
    const oldVariable = oldAlertVariables[index];

    if (!oldVariable) {
      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality: 3,
        new_value: formatStringToNumber(newVariable.green)
      });

      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality: 2,
        new_value: formatStringToNumber(newVariable.green)
      });
      return;
    }

    if (newVariable.green !== oldVariable.green) {
      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality: 3,
        old_value: formatStringToNumber(oldVariable.green),
        new_value: formatStringToNumber(newVariable.green)
      });

      result.push({
        oilwell: oilwell,
        alert_type: newVariable.alert_type,
        criticality: 2,
        old_value: formatStringToNumber(oldVariable.red),
        new_value: formatStringToNumber(newVariable.green)
      });
    }
  });

  return result;
};

export default AlertVariablesTab;
