import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import router from './routes/root';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { useEffect } from 'react';

const GTM_TRACKING_ID = import.meta.env.VITE_GTM_TRACKING_ID;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 4 // 24 hours
    }
  }
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage
});

persistQueryClient({
  persister: localStoragePersister,
  maxAge: 1000 * 60 * 60 * 24,
  queryClient: queryClient as any
});

interface AppProps {
  instance: PublicClientApplication;
}

const App: React.FC<AppProps> = ({ instance }) => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_TRACKING_ID,
      dataLayer: {
        userId: instance.getActiveAccount()?.localAccountId
      }
    });
  }, []);

  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
