import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

export default function initSentry() {
  const SENTRY_ENVIRONMENT = import.meta.env.DEV
    ? 'development'
    : import.meta.env.VITE_SENTRY_ENVIRONMENT;

  Sentry.init({
    enabled: SENTRY_ENVIRONMENT !== 'development',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error']
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    profilesSampleRate: 0.1, // Set sampling rate for profiling - this is relative to tracesSampleRate

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /.*portaldedados\.origemenergia\.com/,
      /.*microsoftonline\.com/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
