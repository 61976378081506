interface SearchBarProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
}

const SearchBar = ({
  placeholder = 'Pesquisar...',
  onChange
}: SearchBarProps) => {
  return (
    <div className="w-full relative">
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        className="w-full px-3 py-2 pr-10 border border-gray/20 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
      />
      <button className="absolute right-3 top-1/2 -translate-y-1/2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </button>
    </div>
  );
};

export default SearchBar;
