import { equipment } from '../arrays/equipment';
import { objectify } from './WorflowTests';

export const PiopFilters = [
  {
    key: 'station',
    name: 'Estação',
    values: [
      'FURADO - CAMPO',
      'FURADO - ESTAÇÃO',
      'FURADO - O&M COMPRESSÃO',
      'FURADO - SUBESTAÇÃO',
      'PILAR - CAMPO',
      'PILAR - ESTAÇÃO',
      'PILAR - FLUIDOS',
      'PILAR - LABORATÓRIO',
      'PILAR - O&M COMPRESSÃO',
      'PILAR - P16',
      'PILAR - SUBESTAÇÃO',
      'UPGN'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    key: 'equipment',
    name: 'Equipamentos',
    values: equipment.sort((a, b) => a.localeCompare(b)).map(objectify)
  },
  {
    key: 'priority',
    name: 'Prioridade',

    values: ['Alta', 'Média', 'Baixa'].map(objectify)
  },
  {
    key: 'discipline',
    name: 'Disciplina',
    values: [
      'ANDAIME',
      'ANDAIME - AJUSTE',
      'ANDAIME - DESMONTAGEM',
      'ANDAIME - MONTAGEM',
      'AUTOMACAO',
      'C&M',
      'C&M - CIVIL',
      'C&M - CONTRATADA',
      'C&M - ELETRICA',
      'C&M - INSTRUMENTACAO',
      'C&M - MECANICA CONTRATADA',
      'C&M - MORIAH',
      'C&M - OPEN',
      'C&M - PROJETOS ESPECIAIS',
      'C&M - TUBULACAO',
      'CALDEIRARIA',
      'CALDEIRARIA - CONTRATADA',
      'CAMINHÃO CESTO',
      'CAMINHÃO MUNCK',
      'CAMINHÃO PIPA',
      'CAMINHÃO TANQUE DIESEL',
      'CAMINHÃO VÁCUO',
      'CARRETA TANQUE LIMPO 30M³',
      'CARRETA TANQUE SUJO 30M³',
      'CAVALO MUNCK + PRANCHA',
      'DUTOS',
      'EBS PERFURAÇÕES',
      'ELETRICA',
      'ELÉTRICA - CONTRATADO',
      'ELEVAÇÃO',
      'EMPILHADEIRA',
      'EMPRESA ELOS',
      'FABRICAÇÃO  - PILAR',
      'FABRICAÇÃO - FURADO',
      'GUINDASTE',
      'INP',
      'INSPECAO',
      'INSPEÇÃO',
      'INSPECAO - CALIBRAÇÃO',
      'INSPECAO - QUALIDADE',
      'INSPECAO - SPIE',
      'INSPEÇÃO CONTRATADA',
      'INSTRUMENTACAO',
      'JATEAMENTO PIPE SHOP',
      'L&H',
      'L&M',
      'LAVAGEM INDUSTRIAL',
      'LIMPEZA',
      'MAN. CIVIL',
      'MAN. PREDIAL',
      'MAN. SERVIÇOS GERAIS',
      'MANUT. LOC E FAIXA',
      'MECANICA',
      'MECÂNICA - CONTRATADO',
      'MECÂNICA - O&M',
      'MECANICA CONFIABILIDADE',
      'MECANICA CONTRATADA',
      'MECANICA DE POÇOS',
      'MECÂNICA INFRARED',
      'MECANICA LUBRIFICAÇÃO',
      'MECANICA LUBRIN',
      'MEDIÇÃO FISCAL',
      'MEDIÇÃO FISCAL - CONTRATADO',
      'O&M - INSTRUMENTACAO',
      'O&M - MECANICA',
      'O&M - MECANICA - TERCEIRIZADO',
      'O&M COMPRESSÃO',
      'OPERAÇÃO',
      'PARADA PROGRAMADA',
      'PECOM',
      'PINTURA',
      'PINTURA - CONTRATADA',
      'PINTURA-CONTRATADO',
      'PLANEJAMENTO',
      'PLATAFORMA ELEVATÓRIA (GIRAFINHA)',
      'POLIGUINDASTE',
      'PRODUÇÃO',
      'SEG',
      'SOLDA',
      'SOP',
      'SOP - LIMPEZA TÉCNICA',
      'SOP - SERVIÇOS GERAIS',
      'TÉC. COMUNIC. TIC',
      'TERRAPLANAGEM',
      'TIC',
      'TRANSPORTE CARGAS',
      'TRANSPORTE CARGAS - CONTRATADA',
      'UCAQ'
    ].map(objectify)
  },
  {
    key: 'status',
    name: 'Status da PT',
    values: [
      'Requisição da PT',
      'Correção da PT',
      'Elaboração Emitente',
      'Elaboração Coemitente',
      'Validação Liderança',
      'Validação QSMS',
      'Emissão em Campo',
      'Aprovação Téc. Segurança',
      'Renovação Requisitante',
      'Renovação Emitente',
      'Execução do Serviço',
      'Quitação Emitente',
      'Quitação Coemitente',
      'Quitação Téc. Segurança',
      'Reprogramação da PT',
      'PT Cancelada',
      'PT Concluída',
      'Renovação da PT'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  }
];
