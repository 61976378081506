export type AlertVariablesDTO = Record<string, AlertVariableDTO>;

export type AlertVariableDTO = {
  throughput: AlertConfigDTO[];
  pressure: AlertConfigDTO[];
  elevation: AlertConfigDTO[];
};

export type AlertConfigDTO = {
  alert_type: string;
  label: string;
  visibility_type:
    | 'absolute_difference_alert'
    | 'ratio_alert'
    | 'percentage_change_alert';
  red: number;
  green: number;
  last_update?: LastUpdate;
};

export type LastUpdate = {
  date: Date;
  user: string;
  details: UpdateDetail[];
};

export type UpdateDetail = {
  criticality: number;
  old_value: number;
  new_value: number;
};

export type AlertVariables = Record<string, AlertVariable>;

export type AlertVariable = {
  throughput: SectionConfig;
  pressure: SectionConfig;
  elevation: SectionConfig;
};

export type SectionConfig = {
  absolute_difference_alert: AlertConfigDTO[];
  ratio_alert: AlertConfigDTO[];
  percentage_change_alert: AlertConfigDTO[];
};

export type AlertConfigChangeDTO = {
  oilwell: string;
  alert_type: string;
  criticality: number;
  old_value?: number;
  new_value: number;
};

export type UserSettingsDTO = {
  email: string;
  active?: boolean;
  receive_troughput_email?: boolean;
  receive_pressure_email?: boolean;
  receive_elevation_email?: boolean;
  receive_no_pressure_measure_email?: boolean;
};

export type AlertSection = 'throughput' | 'pressure' | 'elevation';

export const alertSectionLabelResource: Record<AlertSection, string> = {
  throughput: 'Vazão',
  pressure: 'Pressão',
  elevation: 'Elevação'
};

export const mapAlertVariablesDTOToAlertVariables = (
  alertVariablesDTO: AlertVariablesDTO
): AlertVariables => {
  const alertVariables: AlertVariables = {};

  Object.entries(alertVariablesDTO).forEach(([oilwell, oilwellAlerts]) => {
    const alertVariable: AlertVariable = {
      throughput: {
        absolute_difference_alert: [],
        ratio_alert: [],
        percentage_change_alert: []
      },
      pressure: {
        absolute_difference_alert: [],
        ratio_alert: [],
        percentage_change_alert: []
      },
      elevation: {
        absolute_difference_alert: [],
        ratio_alert: [],
        percentage_change_alert: []
      }
    };

    Object.entries(oilwellAlerts).forEach(([section, configs]) => {
      configs.forEach(config => {
        alertVariable[section as AlertSection][config.visibility_type]?.push(
          config
        );
      });
    });

    alertVariables[oilwell] = alertVariable;
  });

  return alertVariables;
};

export const criticalityLabelResource: Record<number, string> = {
  2: 'red',
  3: 'green'
};

export const getAlertTypeLabelResource = (
  alertVariables: AlertVariables
): Record<string, string> => {
  const alertTypeLabelMap: Record<string, string> = {};

  Object.values(alertVariables).forEach(oilwellAlertTypes => {
    Object.values(oilwellAlertTypes).forEach(alertConfigs => {
      Object.values(alertConfigs).forEach(configs => {
        configs.forEach(config => {
          alertTypeLabelMap[config.alert_type] = config.label;
        });
      });
    });
  });

  return alertTypeLabelMap;
};
