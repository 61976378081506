import { SchematicInterface } from '../interface';

export const mockSchematic: SchematicInterface = {
  strutural_data: {
    profundidade_medida: 1212,
    profundidade_vertical: 1212,
    let_down: 100,
    kop: 100
  },
  parte_superior: {
    numero_de_colunas_de_producao: 1,
    equipamento_de_superficie: {
      // tipo: "unidade-de-bombeio"
      // tipo: "arvore-de-natal"
      // tipo: "cabecote-bcp",
      tipo: 'arvore-de-natal'
    },
    valvula_mestra: [
      {
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 1 Test',
            modelo: 'Valvula 1 Test',
            pressao: '3123',
            diametro: '7'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        curta: null
      },
      {
        curta: null,
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 2 Test',
            modelo: 'Valvula 2 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: true,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      }
    ],
    valvulas_pistoneio: [
      {
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 3 Test',
            modelo: 'Valvula 3 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: false,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        curta: null
      },
      {
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 3 Test',
            modelo: 'Valvula 3 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: false,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        curta: null
      }
    ],
    valvulas_laterais_producao: [
      {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 5 Test',
            modelo: 'Valvula 5 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 5 Test',
            modelo: 'Valvula 5 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      }
    ],
    anulares: {
      A: {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 7 Test',
            modelo: 'Valvula 7 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 8 Test',
            modelo: 'Valvula 8 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      B: {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 9 Test',
            modelo: 'Valvula 9 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 8 Test',
            modelo: 'Valvula 8 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      C: {
        normal: {
          has_valvula: false,
          valvula_info: {
            fabricante: 'Empresa 11 Test',
            modelo: 'Valvula 11 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: true,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 8 Test',
            modelo: 'Valvula 8 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      D: {
        normal: null,
        oposta: null
      },
      E: {
        normal: null,
        oposta: null
      }
    }
  },
  parte_inferior: {
    numero_de_colunas_de_producao: 2,
    equipamento_de_subsuperficie: [
      {
        coluna_de_producao: 'longa',
        tipo: 'mandril',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 200,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'curta',
        tipo: 'mandril',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 200,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'packer',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 400,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'packer',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 400,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'curta',
        tipo: 'packer',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 400,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'dhsv',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 600,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'sliding-sleeve',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 800,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'curta',
        tipo: 'sliding-sleeve',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 800,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'standing-valve',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1000,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'curta',
        tipo: 'standing-valve',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1000,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'bpv',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1200,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },

      {
        coluna_de_producao: 'curta',
        tipo: 'bpv',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1200,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'blanking-plug',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'curta',
        tipo: 'blanking-plug',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    hastes: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    condicoes_mecanicas: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    obstrucoes: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'Não permite ferramenta com OD 1.90” @ 195,0m',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'Não permite ferramenta com OD 1.90” @ 195,0m',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    peixes: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao:
          'Carcaça de BPP fixada em tampão de cimento decantado + carcaça de BPP e cone de broca',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        topo: 1125,
        base: 1135,
        fabricante: 'empresa_1',
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao:
          'Carcaça de BPP fixada em tampão de cimento decantado + carcaça de BPP e cone de broca',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        topo: 1225,
        base: 1235,
        fabricante: 'empresa_1',
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    revestimento: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        peso: 123,
        grau: 'K-55',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_externo_txt: '213',
        diametro_interno: 123,
        fabricante: 'empresa_1',
        topo: 5,
        profundidade: 3800,
        cimentacoes: [
          {
            tipo: 'Tubos 2.7/8 EU',
            descricao: 'lb/ft',
            quantidade: 2,
            fabricante: 'empresa_1',
            topo: 600,
            base: 3800,
            csb_info: {
              has_csb: true,
              csb: {
                nome: 'csb_primario',
                nivel: 1
              }
            }
          }
        ],
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        peso: 123,
        grau: 'K-55',
        quantidade: 2,
        diametro_externo: 313,
        diametro_externo_txt: '313',
        diametro_interno: 123,
        fabricante: 'empresa_1',
        topo: 5,
        profundidade: 600,
        cimentacoes: [
          {
            tipo: 'Tubos 2.7/8 EU',
            descricao: 'lb/ft',
            quantidade: 2,
            fabricante: 'empresa_1',
            topo: 0,
            base: 600,
            csb_info: {
              has_csb: true,
              csb: {
                nome: 'csb_primario',
                nivel: 1
              }
            }
          }
        ],
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ]
  },
  conjunto_solidario_de_barreiras: {
    saude_do_poco: 2,
    criticidade_do_poco: 8,
    integridade_do_poco: 3,
    csb: [
      {
        nome: 'csb_primario',
        nivel: 1
      },
      {
        nome: 'csb_primario',
        nivel: 2
      },
      {
        nome: 'csb_secundario',
        nivel: 1
      }
    ]
  }
};
