import { Link } from 'react-router-dom';

import { formatDate, numberFormatter2 } from '../../utils/utils';
import LateClock, { LateOptions } from '../atoms/LateClock';
import MenuOptions from '../atoms/MenuOptions';

export enum InterventionStatus {
  Qeued = 'Alocado em Fila',
  OnIntervention = 'Em Intervenção',
  Concluded = 'Concluído'
}

export type InterventionPanelCardRequestData = {
  id: number;
  interventionCode: string;
  late: LateOptions;
} & InterventionPanelCardContentProps;

export type InterventionPanelCardData = {
  id: number;
  interventionCode: string;
  data: InterventionPanelCardContentProps;
  late: LateOptions;
};

export type InterventionPanelCardContentProps = {
  id: number;
  estimatedDurationDays: number;
  elapsedDays: number;
  oilwell: string;
  status: InterventionStatus;
  startDate: string;
  gas: number;
  oil: number;
  water: number;
};

const InterventionPanelCard = ({
  id,
  interventionCode,
  data,
  late
}: InterventionPanelCardData) => {
  const editIssueURL = `${import.meta.env.VITE_REDMINE_URL}/issues/${id}`;
  const seeReportURL =
    data.status === InterventionStatus.Concluded
      ? `/home/intervention-panel/intervention-final-report/${id}`
      : `/home/intervention-panel/${id}`;
  const byPassLate =
    late === LateOptions.NotLate ? LateOptions.NotLate : LateOptions.Late;

  return (
    <div className="flex flex-col shadow-light hover:outline hover:outline-blue-400 rounded-lg px-3 py-2">
      <div className="flex items-center text-xs">
        {data.status !== InterventionStatus.Qeued ? (
          <span className="font-bold text-[8px] text-gray/80">
            {interventionCode}
          </span>
        ) : null}
        <div className="flex flex-row relative items-center px-1 ml-auto">
          <LateClock id={`${id}`} late={byPassLate}></LateClock>

          <MenuOptions
            buttonClassName="absolute translate-x-[1rem]"
            menuClassName="translate-x-[calc(25%+4px)] translate-y-[3%]"
            nameId={`${id}-menu-options-kanban`}
            links={[
              { label: 'Ver', url: seeReportURL },
              { label: 'Editar', url: editIssueURL }
            ]}
          />
        </div>
      </div>

      <Link to={seeReportURL}>
        <div className={`w-full flex flex-col gap-1`}>
          <InterventionPanelCardContent {...data} />
        </div>
      </Link>
    </div>
  );
};

export default InterventionPanelCard;

export const InterventionPanelCardContent = ({
  id,
  estimatedDurationDays,
  elapsedDays,
  oilwell,
  status,
  startDate,
  gas,
  oil,
  water
}: InterventionPanelCardContentProps) => {
  const statusBackgroundColor =
    status === InterventionStatus.OnIntervention
      ? 'bg-[#BEF2C788]'
      : status === InterventionStatus.Qeued
        ? 'bg-[#D7DFFF77]'
        : 'bg-[#D7DFFF]';

  const statusTextColor =
    status === InterventionStatus.OnIntervention
      ? 'text-[#33733E]'
      : status === InterventionStatus.Qeued
        ? 'text-[#125074]'
        : 'text-primary';
  return (
    <>
      <div className="flex flex-row gap-2 items-center text-nowrap flex-wrap">
        <span className="font-bold text-xs text-gray flex">{oilwell}</span>

        <div
          className={`flex-grow-0 rounded-[3px] px-1 py-1 text-[8px] leading-[8px] h-min ${statusBackgroundColor} ${statusTextColor}`}
        >
          {status}
        </div>
      </div>

      <div className="flex flex-col text-nowrap">
        <span className="text-[10px] text-gray/80">
          Início: <strong>{startDate ? formatDate(startDate) : '-'}</strong>
        </span>

        <span className=" text-[10px] text-gray/80">
          Duração (dias):{' '}
          <strong>
            {elapsedDays || '-'}/{estimatedDurationDays || '-'}
          </strong>
        </span>
      </div>

      <div className="flex gap-1 flex-wrap">
        {[
          { name: 'Gás', value: gas, unit: 'Mm³/d' },
          { name: 'Óleo', value: oil, unit: 'bbl/d' },
          { name: 'Água', value: water, unit: 'bbl/d' }
        ].map((tag, idx) => (
          <div
            className={
              'flex flex-col flex-1 rounded-[2px] px-1 py-[1px] text-[8px] bg-[#D7DFFF] text-primary text-nowrap max-w-[56px]'
            }
            key={`${id}${tag.name}`}
          >
            <div>{tag.name}</div>
            <strong>
              {numberFormatter2().format(tag.value)} {tag.unit}
            </strong>
          </div>
        ))}
      </div>
    </>
  );
};
