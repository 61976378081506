import { getAllOilwells } from '../../services/oilwells.services';

export const fieldValues = [
  'Anambé',
  'Arapaçu',
  'Cidade de São Miguel dos Campos',
  'Conceição',
  'Fazenda Matinha',
  'Fazenda Santa Rosa',
  'Furado',
  'Paru',
  'Pilar',
  'Quererá',
  'São Miguel dos Campos'
];

const criticityValues = [
  {
    label: 'Alta',
    value: 1
  },
  {
    label: 'Média',
    value: 2
  },
  {
    label: 'Baixa',
    value: 3
  }
];

const fluidTypeValues = ['Óleo', 'Água', 'GNA', 'NA'];

export const objectify = (item: string) => ({ label: item, value: item });

export const oilwellStation = fieldValues.map(objectify);
const oilwellHub = [
  { label: 'Pilar', value: 'PILAR' },
  { label: 'Furado', value: 'FURADO' },
  { label: 'Tucano Sul', value: 'PTS' }
];

export const oilwellFluidType = fluidTypeValues.map(objectify);

export const getOilWellAsync: any = async (search?: any) => {
  const oilwells = await getAllOilwells(0, 50, search);

  return oilwells?.data?.map((it: any) => ({
    value: it.name,
    label: it.name
  }));
};

export const elevationMethodValues = [
  'BCS',
  'BCP',
  'BM',
  'BPZ',
  'NA',
  'GLC',
  'GLI',
  'S'
].map(objectify);

const alertType = [
  { value: 'oil', label: 'Óleo' },
  { value: 'gas', label: 'Gás' },
  { value: 'water', label: 'Água' },
  { value: 'gas_lift', label: 'Gás Lift' },
  { value: 'bsw', label: 'BSW' },
  { value: 'roa', label: 'ROA' },
  { value: 'rga', label: 'RGA' },
  { value: 'choke', label: 'Choke' }
];

const pressureAlertType = [
  { value: 'head_pressure', label: 'Pressão de Cabeça' },
  { value: 'line_pressure', label: 'Pressão de Linha' },
  { value: 'annular_pressure', label: 'Pressão Anular A' },
  { value: 'head_temperature', label: 'Temperatura da Cabeça' },
  { value: 'line_temperature', label: 'Temperatura da linha' }
];

const elevationAlertType = [
  [
    { value: 'head_pressure', label: 'Pressão de Cabeça' },
    { value: 'line_pressure', label: 'Pressão de Linha' },
    { value: 'annular_pressure', label: 'Pressão Anular A' },
    { value: 'frequency', label: 'Frequência' },
    { value: 'voltage', label: 'Tensão' },
    { value: 'current', label: 'Corrente' },
    { value: 'intake_pressure', label: 'Pressão de Intake' },
    { value: 'discharge_pressure', label: 'Pressão de Descarga' },
    { value: 'engine_temperature', label: 'Temperatura do Motor' }
  ],
  [
    { value: 'head_pressure', label: 'Pressão de Cabeça' },
    { value: 'line_pressure', label: 'Pressão de Linha' },
    { value: 'engine_current', label: 'Corrente do Motor' },
    { value: 'ub_cpm', label: 'CPM UB' }
  ],
  [
    { value: 'head_pressure', label: 'Pressão de Cabeça' },
    { value: 'line_pressure', label: 'Pressão de Linha' },
    { value: 'annular_pressure', label: 'Pressão Anular A' },
    { value: 'rod_rpm', label: 'RPM das Hastes' },
    { value: 'rod_torque', label: 'Torque das Hastes' },
    { value: 'engine_torque', label: 'Torque do Motor' },
    { value: 'current', label: 'Corrente' }
  ]
];

export const alertFilters = [
  { key: 'timeRange', name: 'Período', values: [] },
  { key: 'station', name: 'Estação', values: oilwellHub },
  { key: 'field', name: 'Campo', values: oilwellStation },
  {
    key: 'oilwell',
    name: 'Código de Poço',
    values: [],
    asyncFn: getOilWellAsync
  },
  { key: 'alertType', name: 'Tipo de Alerta', values: alertType },
  { key: 'criticity', name: 'Criticidade', values: criticityValues },
  {
    type: 'fromTo',
    name: 'RGA',
    key: 'rga',
    values: []
  },
  {
    type: 'fromTo',
    name: 'ROA',
    key: 'roa',
    values: []
  },
  { key: 'fluid', name: 'Fluido do Poço', values: oilwellFluidType },
  {
    key: 'elevationMethod',
    name: 'Método de Elevação',
    values: elevationMethodValues
  },
  {
    key: 'pressureSystem',
    name: 'Sistema de Pressão',
    values: ['Baixa', 'Média', 'Alta'].map(objectify)
  }
];

export const pressureAlertFilters = [
  { key: 'timeRange', name: 'Período', values: [] },
  { key: 'station', name: 'Estação', values: oilwellHub },
  { key: 'field', name: 'Campo', values: oilwellStation },
  {
    key: 'oilwell',
    name: 'Código de Poço',
    values: [],
    asyncFn: getOilWellAsync
  },
  { key: 'alertType', name: 'Tipo de Alerta', values: pressureAlertType },
  { key: 'criticity', name: 'Criticidade', values: criticityValues },
  { key: 'fluid', name: 'Fluido do Poço', values: oilwellFluidType },
  {
    key: 'elevationMethod',
    name: 'Método de Elevação',
    values: elevationMethodValues
  },
  {
    key: 'with_automation',
    name: 'Poços Com Automação',
    values: [
      { label: 'Sim', value: 'true' },
      { label: 'Não', value: 'false' }
    ]
  }
];

export const elevationAlertFilters = [
  { key: 'timeRange', name: 'Período', values: [] },
  { key: 'station', name: 'Estação', values: oilwellHub },
  { key: 'field', name: 'Campo', values: oilwellStation },
  {
    key: 'oilwell',
    name: 'Código de Poço',
    values: [],
    asyncFn: getOilWellAsync
  },

  { key: 'criticity', name: 'Criticidade', values: criticityValues }
];

export function getFilterByElevationMethod(elevationMethod: number) {
  const filters = elevationAlertFilters.concat([
    {
      key: 'alertType',
      name: 'Tipo de Alerta',
      values: elevationAlertType[elevationMethod]
    }
  ]);

  return filters;
}
