export type RFPData = {
  custom_fields: {
    [key: string]: any[] | string | number | null;
  };
  issue: {
    id: number;
    subject: string;
    status_id: number;
    created_on: string;
    updated_on: string;
  };
};

export type RFP = {
  rfp: RFPData;
  last_rdp: RFPData;
  company: string;
  estimated_expense: number;
  sondas_empresas: { sonda: string; empresa: string; group_id: number }[];
  ope: number;
  stb: number;
  tnf: number;
};

export namespace RFPSchema {
  export enum RFPCfIds {
    DATA_CRIACAO = 248,
    CAMPO = 3645,
    DATA_INICIO_PERFURACAO = 3642,
    DATA_FIM_PERFURACAO = 3643,
    DURACAO_ESTIMADA = 3644,
    BAP = 3650,
    MR = 3654,
    FUNDO_POCO = 3651,
    LET_DOWN = 3655,
    TRAJETORIA = 3652,
    GRADIENTE_DE_TEMPERATURA = 3656,
    KOP = 3653,
    OBJETIVO_DA_PERFURACAO = 3646,
    GRID_COORDENADAS = 3660,
    GRID_FLUIDO = 3665,
    GRID_EQUIPAMENTOS_DE_SUPERFICIE = 3669,
    GRID_PEIXES = 3671,
    GRID_DIRECIONAL = 2849,
    GRID_BROCAS = 3707,
    GRID_REVESTIMENTO = 3721,
    GRID_CIMENTACAO = 3696,
    GRID_PROPRIEDADES_DOS_FLUIDOS_UTILIZADOS = 3676,
    GRID_DEMAIS_PROPRIEDADES_DOS_FLUIDOS_UTILIZADOS = 3695,
    GRID_BHA = 3719,
    GRID_RELATORIO_GEOLOGICO = 3092,
    OBSERVACOES_E_DESAFIOS_OPERACIONAIS = 3659,
    GRID_RESUMO_DAS_OPERACOES = 3339
  }

  export enum LastRDPCfIds {
    DATA_CRIACAO = 248,
    DURACAO_REALIZADA = 3191,
    CUSTO_REALIZADO = 3159
  }

  export const getAllCfIds = (): number[] => {
    return Array.from(
      new Set([
        ...Object.values(RFPCfIds)
          .filter(value => typeof value === 'number')
          .map(it => Number(it)),
        ...Object.values(LastRDPCfIds)
          .filter(value => typeof value === 'number')
          .map(it => Number(it))
      ])
    );
  };

  export const getRFPCFIndex = (cf_id: RFPCfIds) => {
    return `rfp.custom_fields.${cf_id}`;
  };

  export const getRFPFieldIndex = (fieldName: string) => {
    return `rfp.issue.${fieldName}`;
  };

  export const getRDPCFIndex = (cf_id: LastRDPCfIds) => {
    return `last_rdp.custom_fields.${cf_id}`;
  };

  export const getRDPFieldIndex = (fieldName: string) => {
    return `last_rdp.issue.${fieldName}`;
  };
}
