import { useAccount } from '@azure/msal-react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import Button from '../../components/atoms/Button';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import PageNavigation from '../../components/molecules/PageNavigation';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';

import { PriorityPageFilter } from '@/constants/filters/PriorityPageFilter';
import { addDays, addMonths, format } from 'date-fns';
import { BiImport } from 'react-icons/bi';
import Datepicker from 'react-tailwindcss-datepicker';
import priority from '../../constants/tableColumns/piop/priority';
import {
  getPiopOrder,
  getPiopOrdersXlsx,
  updatePiopPriority,
  updatePiopSuggestedPrioritizedDates
} from '../../services/bdos.services';
import { PiopReportFiltersAtom } from '../../state/piop.report.filters';

export const EditTable = () => {
  const [issues, setIssues] = useState({ data: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [filtersState, setFilters] = useRecoilState(PiopReportFiltersAtom);
  const [filteredData, setFilteredData] = useState(filtersState);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const perPage = 30;

  const user = useAccount();

  const handleFilter = (isClearing?: boolean) => {
    !isClearing
      ? setFilteredData(filtersState)
      : setFilteredData({
          estacao: [],
          equipamentos: [],
          localInstalacao: [],
          timeRange: {
            endDate: null,
            startDate: null
          },
          timeRangeDataPriorizada: {
            endDate: null,
            startDate: null
          },
          timeRangeDataCriacao: {
            endDate: null,
            startDate: null
          },
          ordem: '',
          nota: '',
          statusOrdem: ['Não Concluída'],
          descricaoBreve: '',
          cenarioDePriorizacao: [],
          tipoDeAtividade: []
        });
  };

  const methods = useForm();

  const getPiopOrders = async (
    currentPage: number,
    perPage: number,
    filteredData: any
  ) => {
    setIsLoading(true);

    const res: any = await getPiopOrder(currentPage, perPage, filteredData);

    setIssues({
      data: res.data,
      total: res.total
    });

    methods.reset({
      data: res.data
    });

    setIsLoading(false);
  };

  const handleExport = async () => {
    setIsLoadingExcel(true);
    try {
      await getPiopOrdersXlsx(filteredData);
    } catch (error) {
      toast.error('Erro ao exportar dados');
    } finally {
      setIsLoadingExcel(false);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      await updatePiopPriority(data.data);
      toast.success('Prioridades alteradas com sucesso!');

      submitPiopSuggestedPrioritizedDatesToRedmine(data.data);
    } catch (e) {
      toast.error('Erro ao atualizar prioridade');
    }
  };

  const submitPiopSuggestedPrioritizedDatesToRedmine = async (data: any) => {
    try {
      await updatePiopSuggestedPrioritizedDates(data);
    } catch (e) {
      console.log(e);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (
      ![
        'Portal.Admin',
        'Portal.Piop',
        'Portal.PiopAdmin',
        'Portal.SuperAdmin'
      ].some(it => user?.idTokenClaims?.roles?.includes(it))
    )
      navigate('/home/piop');
  }, []);

  useEffect(() => {
    getPiopOrders(currentPage, perPage, filteredData);
  }, [filteredData, currentPage]);

  const containerRef = useRef(null);

  const handleScroll = (e: any) => {
    return e.target === containerRef.current;
  };

  return (
    <>
      <PageWrapper>
        <div className="bg-white rounded-[10px] py-4 px-6 h-full mt-4">
          <div className="w-full flex justify-between">
            <h1 className="text-gray font-bold text-lg mb-4">
              Comitê de Priorização
            </h1>

            {!isLoading && (
              <button
                className="h-8 w-8 flex justify-center items-center rounded-full bg-primary disabled:cursor-not-allowed"
                onClick={handleExport}
                disabled={isLoadingExcel}
                title="Exportar"
              >
                {isLoadingExcel ? (
                  <Spinner size={16} color="white" />
                ) : (
                  <BiImport size={16} color="white" />
                )}
              </button>
            )}
          </div>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                ref={containerRef}
                className="h-full max-h-[calc(100vh-280px)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full"
              >
                {isLoading ? (
                  <Spinner size={36} />
                ) : !issues.data.length ? (
                  <EmptyMessage message="Nenhum dado foi encontrado" />
                ) : (
                  <Table
                    key="priority"
                    columns={priority}
                    headerClassName="sticky top-0 z-10 bg-white"
                    data={issues.data?.map((it: any) => ({
                      ...it,
                      priority: it.prioridade,
                      nota: it.nota?.replace('.0', '')
                    }))}
                    handleScroll={handleScroll}
                  />
                )}
              </div>

              {!!issues && !isLoading && (
                <>
                  <PageNavigation
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    className="mt-3"
                    totalPages={Math.ceil(issues.total / perPage)}
                    perPage={issues.total > perPage ? perPage : issues.total}
                  />

                  <div className="w-full flex justify-end">
                    <Button title="Salvar" type="submit" />
                  </div>
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </PageWrapper>

      <SideFilter
        atom={PiopReportFiltersAtom}
        filters={PriorityPageFilter}
        applyChanges={handleFilter}
        floatButtonClassName="top-28"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Data Priorizada</h3>
          <Datepicker
            popoverDirection="down"
            value={filtersState.timeRangeDataPriorizada}
            showShortcuts={true}
            configs={
              {
                shortcuts: {
                  today: 'Hoje',
                  tomorrow: {
                    text: 'Amanhã',
                    period: {
                      start: format(new Date(), 'yyyy-MM-dd'),
                      end: format(addDays(new Date(), 1), 'yyyy-MM-dd')
                    }
                  },
                  next60Days: {
                    text: 'Próximos 60 dias',
                    period: {
                      start: format(new Date(), 'yyyy-MM-dd'),
                      end: format(addMonths(new Date(), 2), 'yyyy-MM-dd')
                    }
                  },
                  next90Days: {
                    text: 'Próximos 90 dias',
                    period: {
                      start: format(new Date(), 'yyyy-MM-dd'),
                      end: format(addMonths(new Date(), 3), 'yyyy-MM-dd')
                    }
                  },
                  thisYear: {
                    text: 'Esse ano',
                    period: {
                      start: `${new Date().getFullYear()}-01-01`,
                      end: `${new Date().getFullYear()}-12-31`
                    }
                  }
                },
                footer: {
                  cancel: 'CText',
                  apply: 'AText'
                }
              } as any
            }
            primaryColor={'sky'}
            onChange={(val: any) =>
              setFilters((old: any) => ({
                ...old,
                timeRangeDataPriorizada: val
              }))
            }
            i18n="pt-br"
            placeholder="Selecione uma data"
            inputClassName={
              'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
            }
            // maxDate={new Date()}
            classNames={{
              toggleButton() {
                return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
              }
            }}
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Data de Criação</h3>
          <Datepicker
            popoverDirection="down"
            value={filtersState.timeRangeDataCriacao}
            showShortcuts={true}
            configs={
              {
                shortcuts: {
                  today: 'Hoje',
                  tomorrow: {
                    text: 'Amanhã',
                    period: {
                      start: format(new Date(), 'yyyy-MM-dd'),
                      end: format(addDays(new Date(), 1), 'yyyy-MM-dd')
                    }
                  },
                  next60Days: {
                    text: 'Próximos 60 dias',
                    period: {
                      start: format(new Date(), 'yyyy-MM-dd'),
                      end: format(addMonths(new Date(), 2), 'yyyy-MM-dd')
                    }
                  },
                  next90Days: {
                    text: 'Próximos 90 dias',
                    period: {
                      start: format(new Date(), 'yyyy-MM-dd'),
                      end: format(addMonths(new Date(), 3), 'yyyy-MM-dd')
                    }
                  },
                  thisYear: {
                    text: 'Esse ano',
                    period: {
                      start: `${new Date().getFullYear()}-01-01`,
                      end: `${new Date().getFullYear()}-12-31`
                    }
                  }
                },
                footer: {
                  cancel: 'CText',
                  apply: 'AText'
                }
              } as any
            }
            primaryColor={'sky'}
            onChange={(val: any) =>
              setFilters((old: any) => ({
                ...old,
                timeRangeDataCriacao: val
              }))
            }
            i18n="pt-br"
            placeholder="Selecione uma data"
            inputClassName={
              'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
            }
            // maxDate={new Date()}
            classNames={{
              toggleButton() {
                return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
              }
            }}
          />
        </div>
      </SideFilter>
    </>
  );
};
