import Checkbox from '@/components/atoms/Checkbox';
import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { UserSettingsDTO } from '../types';

export const getUserColumns = (
  dirtyValues: UserSettingsDTO[],
  setDirtyValues: (values: UserSettingsDTO[]) => void,
  handleDeletion: (email: string) => Promise<void>,
  isEditing: boolean
) => [
  {
    Header: <Header terciary text="E-mail" id="email" />,
    accessor: 'email',
    Cell: (props: any) => <DefaultCell {...props} className="px-4 text-sm" />
  },
  {
    Header: <Header terciary text="Envio de E-mails" id="alertTypes" />,
    accessor: 'dumbAcessor',
    Cell: UserCheckboxCell(dirtyValues, setDirtyValues, isEditing)
  },
  {
    Header: <Header terciary text="Ações" id="actions" />,
    accessor: 'anotherDumbAcessor',
    Cell: UserDeletionCell({ handleDeletion }),
    width: '5%'
  }
];

const UserCheckboxCell =
  (
    dirtyValues: UserSettingsDTO[],
    setDirtyValues: (values: UserSettingsDTO[]) => void,
    isEditing: boolean
  ) =>
  ({ row: { index } }: any) => {
    const dirtyValue = dirtyValues[index];
    const onChange =
      (
        key:
          | 'receive_troughput_email'
          | 'receive_pressure_email'
          | 'receive_elevation_email'
          | 'receive_no_pressure_measure_email'
      ) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDirtyValues = structuredClone(dirtyValues);
        newDirtyValues[index][key] = e.target.checked;
        setDirtyValues(newDirtyValues);
      };
    return (
      <div className="flex gap-2 px-4">
        <Checkbox
          id="checkbox-throughput"
          label="Vazão"
          checked={dirtyValue?.receive_troughput_email ?? false}
          onChange={onChange('receive_troughput_email')}
          disabled={!isEditing}
          className="text-sm"
        />
        <Checkbox
          id="checkbox-pressure"
          label="Pressão"
          checked={dirtyValue?.receive_pressure_email ?? false}
          onChange={onChange('receive_pressure_email')}
          disabled={!isEditing}
          className="text-sm"
        />
        <Checkbox
          id="checkbox-elevation"
          label="Métodos de elevação bombeados"
          checked={dirtyValue?.receive_elevation_email ?? false}
          onChange={onChange('receive_elevation_email')}
          disabled={!isEditing}
          className="text-sm"
        />
        <Checkbox
          id="checkbox-noMeasurement"
          label="Poço sem medição de pressão"
          checked={dirtyValue?.receive_no_pressure_measure_email ?? false}
          onChange={onChange('receive_no_pressure_measure_email')}
          disabled={!isEditing}
          className="text-sm"
        />
      </div>
    );
  };

const UserDeletionCell = ({
  handleDeletion
}: {
  handleDeletion: (email: string) => Promise<void>;
}) => {
  const cell = (props: any) => {
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

    return (
      <>
        <div className="flex items-center justify-center">
          <button
            className="text-gray hover:bg-primary hover:text-white hover:rounded-full p-2"
            onClick={() => setModalDeleteIsOpen(old => !old)}
          >
            <FaTrash size={16} />
          </button>
        </div>

        {modalDeleteIsOpen && (
          <ModalConfirmation
            confirmAction={() => handleDeletion(props?.row?.original?.email)}
            title="Deletar usuário"
            description="Tem certeza que deseja deletar este usuário? Essa é uma ação permanente."
            confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
            isOpen={true}
            setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
          />
        )}
      </>
    );
  };

  return cell;
};
