import { SchematicInterface } from '../interface';
import Poco7ANB16DAL from '../mock/tests/7-ANB-16D-AL.json';
import Poco7ANB2DAL from '../mock/tests/7-ANB-2D-AL.json';
import Poco7ANB7DAL from '../mock/tests/7-ANB-7D-AL.json';
import Poco7FU11DAL from '../mock/tests/7-FU-11D-AL.json';
import Poco7FU50AL from '../mock/tests/7-FU-50-AL.json';
import Poco7PIR159DAL from '../mock/tests/7-PIR-159D-AL.json';
import Poco7PIR253DAL from '../mock/tests/7-PIR-253D-AL.json';
import Poco7PIR28AL from '../mock/tests/7-PIR-28-AL.json';
import Poco7PIR38AL from '../mock/tests/7-PIR-38-AL.json';
import Poco7PIR99AL from '../mock/tests/7-PIR-99-AL.json';

export const mockSchematic: SchematicInterface = {
  strutural_data: {
    profundidade_medida: 1212,
    profundidade_vertical: 1212,
    let_down: 100,
    kop: 100
  },
  parte_superior: {
    numero_de_colunas_de_producao: 1,
    equipamento_de_superficie: {
      // "tipo": "arvore-de-natal"
      // "tipo": "cabecote-bcp",
      tipo: 'arvore-de-natal'
    },
    valvula_mestra: [
      {
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 1 Test',
            modelo: 'Valvula 1 Test',
            pressao: '3123',
            diametro: '7'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        curta: null
      },
      {
        curta: null,
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 2 Test',
            modelo: 'Valvula 2 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: true,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      }
    ],
    valvulas_pistoneio: [
      {
        longa: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 3 Test',
            modelo: 'Valvula 3 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: false,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        curta: null
      },
      {
        longa: {
          has_valvula: false,
          valvula_info: {
            fabricante: 'Empresa 4 Test',
            modelo: 'Valvula 4 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        curta: null
      }
    ],
    valvulas_laterais_producao: [
      {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 5 Test',
            modelo: 'Valvula 5 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 5 Test',
            modelo: 'Valvula 5 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      {
        normal: null,
        oposta: {
          has_valvula: false,
          valvula_info: {
            fabricante: 'Empresa 6 Test',
            modelo: 'Valvula 6 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: true,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      }
    ],
    anulares: {
      A: {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 7 Test',
            modelo: 'Valvula 7 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 8 Test',
            modelo: 'Valvula 8 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      B: {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 9 Test',
            modelo: 'Valvula 9 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 10 Test',
            modelo: 'Valvula 10 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'flange',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      C: {
        normal: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 11 Test',
            modelo: 'Valvula 11 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        },
        oposta: {
          has_valvula: true,
          valvula_info: {
            fabricante: 'Empresa 12 Test',
            modelo: 'Valvula 12 Test',
            pressao: '3123',
            diametro: '3122'
          },
          has_kerotest: false,
          has_flange_or_dhsv: false,
          flange_or_dhsv_type: 'dhsv',
          csb_info: {
            has_csb: true,
            csb: {
              nome: 'csb_primario',
              nivel: 1
            }
          }
        }
      },
      D: {
        normal: null,
        oposta: null
      },
      E: {
        normal: null,
        oposta: null
      }
    }
  },
  parte_inferior: {
    numero_de_colunas_de_producao: 2,
    equipamento_de_subsuperficie: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    hastes: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    condicoes_mecanicas: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    obstrucoes: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        fabricante: 'empresa_1',
        profundidade: 1423,
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    peixes: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        topo: 1125,
        base: 1135,
        fabricante: 'empresa_1',
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        quantidade: 2,
        diametro_externo: 213,
        diametro_interno: 123,
        topo: 1225,
        base: 1235,
        fabricante: 'empresa_1',
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ],
    revestimento: [
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        grau: 'K-55',
        peso: 123,
        quantidade: 2,
        diametro_externo: 213,
        diametro_externo_txt: '213',
        diametro_interno: 123,
        fabricante: 'empresa_1',
        topo: 5,
        profundidade: 1423,
        cimentacoes: [
          {
            tipo: 'Tubos 2.7/8 EU',
            descricao: 'lb/ft',
            quantidade: 2,
            fabricante: 'empresa_1',
            topo: 0,
            base: 600,
            csb_info: {
              has_csb: true,
              csb: {
                nome: 'csb_primario',
                nivel: 1
              }
            }
          }
        ],
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      },
      {
        coluna_de_producao: 'longa',
        tipo: 'Tubos 2.7/8 EU',
        descricao: 'lb/ft',
        grau: 'K-55',
        peso: 123,
        quantidade: 2,
        diametro_externo: 213,
        diametro_externo_txt: '213',
        diametro_interno: 123,
        fabricante: 'empresa_1',
        topo: 5,
        profundidade: 1423,
        cimentacoes: [
          {
            tipo: 'Tubos 2.7/8 EU',
            descricao: 'lb/ft',
            quantidade: 2,
            fabricante: 'empresa_1',
            topo: 0,
            base: 600,
            csb_info: {
              has_csb: true,
              csb: {
                nome: 'csb_primario',
                nivel: 1
              }
            }
          }
        ],
        csb_info: {
          has_csb: true,
          csb: {
            nome: 'csb_primario',
            nivel: 1
          }
        }
      }
    ]
  },
  conjunto_solidario_de_barreiras: {
    saude_do_poco: 2,
    criticidade_do_poco: 8,
    integridade_do_poco: 3,
    csb: [
      {
        nome: 'csb_primario',
        nivel: 1
      },
      {
        nome: 'csb_primario',
        nivel: 2
      },
      {
        nome: 'csb_secundario',
        nivel: 1
      }
    ]
  }
};

export const oilWellTestsMap: any = {
  '7-ANB-2D-AL': Poco7ANB2DAL,
  '7-ANB-7D-AL': Poco7ANB7DAL,
  '7-ANB-16D-AL': Poco7ANB16DAL,
  '7-FU-11D-AL': Poco7FU11DAL,
  '7-FU-50-AL': Poco7FU50AL,
  '7-PIR-28-AL': Poco7PIR28AL,
  '7-PIR-38-AL': Poco7PIR38AL,
  '7-PIR-99-AL': Poco7PIR99AL,
  '7-PIR-159D-AL': Poco7PIR159DAL,
  '7-PIR-253D-AL': Poco7PIR253DAL
};
