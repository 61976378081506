import { Chart, registerables } from 'chart.js';
import {
  format,
  isToday,
  parse,
  parseISO,
  startOfHour,
  subDays
} from 'date-fns';
import { memo } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Column } from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { Header } from '../../../components/atoms/table/Header';
import { TooltipCardItem } from './alerts';

Chart.register(...registerables);

const numberFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const numberFormatter2Decimal = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const ElevationAlertCell = ({
  value,
  row: { index, original },
  column: { id },
  className,
  hideNumber = false
}: any) => {
  const classes: Record<string, { card: string; text: string }> = {
    1: { card: 'bg-red/25 text-red', text: 'text-red' },
    2: { card: 'bg-[#FFF069]/50 text-[#AD953C]', text: 'text-[#AD953C]' },
    3: { card: 'bg-[#10AE88]/25 text-[#0C8A6C]', text: 'text-[#0C8A6C]' }
  };

  const currentClasses = classes[value?.criticity];

  let formattedDate = '';
  if (value?.latest_hour) {
    const parsedDate = parse(
      value?.latest_hour?.split?.('+')?.[0],
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm');
  }

  const currentDay = parseISO(original?.day);
  const currentHour = startOfHour(new Date());
  // O final do periodo anterior é o dia anterior ao dia atual
  const finalPreviousPeriodDate = subDays(currentDay, 1);
  const initialPreviousPeriodDate = subDays(
    finalPreviousPeriodDate,
    original?.interval - 1
  );

  const AlertCard = ({ className }: { className?: string }) => {
    return (
      <div
        className={twMerge(
          'flex flex-row gap-0.5 rounded px-1 py-1 min-w-16 items-center justify-center flex-none',
          currentClasses?.card,
          !value?.percentage && !hideNumber ? 'hidden' : '',
          className,
          hideNumber ? 'min-w-min' : ''
        )}
        data-tooltip-id={`alert-${id}${index}`}
      >
        {!value?.percentage ? (
          <span className="text-xl w-3 h-2 pb-3 pr-2 pl-px leading-3 ">•</span>
        ) : value?.percentage > 0 ? (
          <FaArrowUp size={12} className={currentClasses?.text} />
        ) : (
          <FaArrowDown size={12} className={currentClasses?.text} />
        )}
        {!hideNumber && value?.percentage ? (
          <span
            className={twMerge(
              'font-bold text-[10px] pt-[1px]',
              currentClasses?.text
            )}
          >
            {`${
              Math.abs(value?.percentage) > 0 && Math.abs(value?.percentage) < 1
                ? '>'
                : ''
            } ${numberFormatter.format(
              Math.floor(Math.abs(value?.percentage))
            )}%`}
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className={twMerge(
        'text-[#191919] text-xs whitespace-pre-wrap flex flex-row gap-2 items-center',
        className ?? ''
      )}
      key={`Cell${index}`}
    >
      <span className="mr-auto">
        {value?.new_value !== undefined
          ? numberFormatter2Decimal.format(value?.new_value)
          : '-'}
      </span>

      <AlertCard />

      <ReactTooltip
        id={`alert-${id}${index}`}
        place="bottom"
        variant="light"
        opacity={0.95}
        className="shadow-lg z-50"
      >
        <div className="">
          <span className="font-bold text-sm display flex gap-3 items-center h-8">
            Variação de Medição: <AlertCard className="py-0.5" />
          </span>
          <div className="flex gap-4 my-2 ">
            <div className="flex flex-col gap-2">
              <TooltipCardItem
                label="Medição Acumulada do Dia:"
                value={
                  isToday(currentDay)
                    ? `${format(currentHour, 'HH')} hrs ;${format(currentHour, 'dd/MM/yyyy')}`
                    : format(currentDay, 'dd/MM/yyyy')
                }
              />
              <TooltipCardItem
                label="Data período anterior:"
                value={
                  finalPreviousPeriodDate
                    ? `${original?.interval != 1 ? `${format(initialPreviousPeriodDate, 'dd/MM')} - ` : ''}${format(finalPreviousPeriodDate, 'dd/MM/yyyy')}`
                    : finalPreviousPeriodDate
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <TooltipCardItem
                label="Média:"
                value={numberFormatter2Decimal.format(value?.new_value)}
              />

              <TooltipCardItem
                label="Média:"
                value={numberFormatter2Decimal.format(value?.old_value)}
              />
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

const MemoizedElevationAlertCell = memo(ElevationAlertCell);

export const defaultElevationCols: Column[] = [
  {
    Header: () => (
      <Header className="h-12 items-start text-start" text={'Poço'} id="poço" />
    ),
    accessor: 'oilwell',
    Cell: ({ value }: any) => {
      return (
        <a
          className="text-[#191919] hover:underline text-xs"
          href={`/home/oilwell-info/${value}/summary`}
          target="_blank"
        >
          {value}
        </a>
      );
    },
    minWidth: 105
  }
];

const elevationAlertCols: Column[][] = [
  [
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Cabeça (kgf/cm²)`}
          id="head_pressure"
          info={`Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        />
      ),
      accessor: 'head_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Linha 
           (kgf/cm²)`}
          id="line_pressure"
          info={` Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        />
      ),
      accessor: 'line_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão Anular 
(kgf/cm²)`}
          id="annular_pressure"
          info={` Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        />
      ),
      accessor: 'annular_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Frequência (Hz)`}
          id="frequency"
          info={` Vermelho: >= 10% de variação
Amarelo: entre 5 a 10% de variação 
Verde: <= 5% de variação`}
        />
      ),
      accessor: 'frequency',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Tensão VSD `}
          id="voltage"
          info={`Vermelho: >= 10% de variação
Amarelo: entre 5 a 10% de variação 
Verde: <= 5% de variação`}
        />
      ),
      accessor: 'voltage',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Corrente`}
          id="current"
          info={`Vermelho: >= 15% de variação
Amarelo: entre 5 a 15% de variação 
Verde: <= 5% de variação`}
        />
      ),
      accessor: 'current',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Intake (Psi)`}
          id="intake_pressure"
          info={`Vermelho: >= 25% de variação
Amarelo: entre 5 a 25% de variação 
Verde: <= 5% de variação`}
        />
      ),
      accessor: 'intake_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Descarga (Psi)`}
          id="discharge_pressure"
          info={`Vermelho: >= 25% de variação
Amarelo: entre 5 a 25% de variação 
Verde: <= 5% de variação`}
        />
      ),
      accessor: 'discharge_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Temperatura 
do Motor (°C)`}
          id="engine_temperature"
          info={`Vermelho: >= 10% de variação
Amarelo: entre 5 a 10% de variação 
Verde: <= 5% de variação`}
        />
      ),
      accessor: 'engine_temperature',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    }
  ],
  [
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Cabeça (kgf/cm²)`}
          id="head_pressure"
          info={`Vermelho: >= 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: <= 10% de variação`}
        />
      ),
      accessor: 'head_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Linha 
           (kgf/cm²)`}
          id="line_pressure"
          info={`Vermelho: >= 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: <= 10% de variação`}
        />
      ),
      accessor: 'line_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Corrente do Motor`}
          id="engine_current"
          info={`Vermelho: > 15% de variação
Amarelo: entre 5 a 15% de variação 
Verde: < 5% de variação`}
        />
      ),
      accessor: 'engine_current',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`CPM UB`}
          id="ub_cpm"
          info={`Vermelho: > 15% de variação
Amarelo: entre 5 a 15% de variação 
Verde: < 5% de variação`}
        />
      ),
      accessor: 'ub_cpm',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    }
  ],
  [
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Cabeça (kgf/cm²)`}
          id="head_pressure"
          info={`Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        />
      ),
      accessor: 'head_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão de Linha 
           (kgf/cm²)`}
          id="line_pressure"
          info={` Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        />
      ),
      accessor: 'line_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Pressão Anular 
(kgf/cm²)`}
          id="annular_pressure"
          info={` Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        />
      ),
      accessor: 'annular_pressure',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`RPM das Hastes`}
          id="rod_rpm"
          info={`Vermelho: > 20% de variação
Amarelo: entre 5 a 20% de variação 
Verde: < 5% de variação`}
        />
      ),
      accessor: 'rod_rpm',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Torque das Hastes (Nm)`}
          id="rod_torque"
          info={`Vermelho: > 15% de variação
Amarelo: entre 5 a 15% de variação 
Verde: < 5% de variação`}
        />
      ),
      accessor: 'rod_torque',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Torque do Motor (Nm)`}
          id="engine_torque"
          info={`Vermelho: > 15% de variação
Amarelo: entre 5 a 15% de variação 
Verde: < 5% de variação`}
        />
      ),
      accessor: 'engine_torque',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    },
    {
      Header: () => (
        <Header
          className="h-12 items-start text-start"
          text={`Corrente (A)`}
          id="current"
          info={`Vermelho: > 15% de variação
Amarelo: entre 5 a 15% de variação 
Verde: < 5% de variação`}
        />
      ),
      accessor: 'current',
      Cell: MemoizedElevationAlertCell,
      minWidth: 145
    }
  ]
];

export default elevationAlertCols;
